import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { persistReducer, persistStore} from 'redux-persist';
import storage  from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.min.css';
import  { PersistGate } from 'redux-persist/integration/react';
import allReducers from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
  key: 'root-admin',
  storage,
};

const persistedReducer = persistReducer(persistConfig, allReducers)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} >
      <App/>
    </PersistGate>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
