/*
Component that renders available schedules and lets user select medication schedules.
This component is used in the Resident Search home component.
*/
import React, {Component} from 'react';
import Emoji from '../../shared/components/Emoji/Emoji';
import './scheduleStyles.css';
import { connect } from 'react-redux';
import { setPatientMedsSchedule } from '../../actions/patientSessionActions';
import { logOut } from '../../actions/loginActions';
import { clearDomain } from '../../actions/domainActions';
import { setAmend } from '../../actions/amendActions';
import { isOnTime } from '../../shared/utils';

class Schedule extends Component {
  back = () => {
    this.props.history.push('/confirm');
  }

  continue = () => {
    this.props.setAmend(false)
    this.props.history.push('/medication_selection');
  }
  
  setSchedule = (schedule) => {
    this.props.setPatientMedsSchedule(schedule);
  }

  unlock = () => {
    this.props.setAmend(true);
  }

  render() {
    const { schedule, isAmend } = this.props;
    return(
      <>
        <div className="emojis-container">
          { isAmend ? <Emoji type={schedule === 'morning' ? "active-emoji" : "emoji-container"} option="Morning" text="Morning" select={() => this.setSchedule('morning')} /> : isOnTime('morning') ? <Emoji type={schedule === 'morning' ? "active-emoji" : "emoji-container"} option={schedule === 'morning' ? "MorningWhite": "Morning"} text="Morning" select={() => this.setSchedule('morning')} />: <Emoji type="dimmed" option="Morning" text="Morning" />}
          { isAmend ? <Emoji type={schedule === 'noon' ? "active-emoji" : "emoji-container"} option="Noon" text="Noon" select={() => this.setSchedule('noon')} /> : isOnTime('noon') ? <Emoji type={schedule === 'noon' ? "active-emoji" : "emoji-container"} option={schedule === 'noon'? "NoonWhite": "Noon"} text="Noon" select={() => this.setSchedule('noon')} />: <Emoji type="dimmed" option="Noon" text="Noon" />}
          { isAmend ? <Emoji type={schedule === 'evening' ? "active-emoji" : "emoji-container"} option="Afternoon" text="Evening" select={() => this.setSchedule('evening')} /> : isOnTime('evening') ? <Emoji type={schedule === 'evening' ? "active-emoji" : "emoji-container"} option={schedule === 'evening'? 'EveningWhite': "Afternoon"} text="Evening" select={() => this.setSchedule('evening')} />: <Emoji type="dimmed" option="Afternoon" text="Evening" />}
          { isAmend ? <Emoji type={schedule === 'bedtime' ? "active-emoji" : "emoji-container"} option="Bedtime" text="Bedtime" select={() => this.setSchedule('bedtime')} /> : isOnTime('bedtime') ? <Emoji type={schedule === 'bedtime' ? "active-emoji" : "emoji-container"} option={schedule === 'bedtime'? "BedtimeWhite" : "Bedtime"} text="Bedtime" select={() => this.setSchedule('bedtime')} />: <Emoji type="dimmed" option="Bedtime" text="Bedtime" />}
          { isAmend ? <Emoji type={schedule === 'all' ? "active-emoji" : "emoji-container"} option="All" text="All" select={() => this.setSchedule('all')} /> : isOnTime('all') ? <Emoji type={schedule === 'all' ? "active-emoji" : "emoji-container"} option={schedule === 'all'? "AllWhite" : "All"} text="All" select={() => this.setSchedule('all')} />: <Emoji type="dimmed" option="All" text="All" />}
          { isAmend ? <Emoji type={schedule === 'as_needed' ? "active-emoji" : "emoji-container"} option="As_needed" text="As needed" select={() => this.setSchedule('as_needed')} /> : isOnTime('as_needed') ? <Emoji type={schedule === 'as_needed' ? "active-emoji" : "emoji-container"} option={schedule === 'as_needed'? 'As_neededWhite' : "As_needed"} text="As needed" select={() => this.setSchedule('as_needed')} />: <Emoji type="dimmed" option="As_needed" text="As needed" />}
        </div>
        <div className="break"></div>
    {/*<Buttons amount={schedule.length > 0 ? 2 : 1} options={{back: this.back, continue: this.continue, amend: this.unlock}} type="amend" />*/}
      </>
    );
  }
}

const mapStateToProps = state => ({
  schedule: state.patientSession.schedule,
  medTech: state.patientSession.med_tech,
  patient: state.patientSession.patient,
  status: state.patientSession.status,
  medicationType: state.patientSession.medicationType,
  isAmend: state.amend.amend,
  colors: state.settings.settings,
  domain: state.domain.domain,
});

export default connect(mapStateToProps, { setPatientMedsSchedule, logOut, clearDomain, setAmend })(Schedule);