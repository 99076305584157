import { 
    GET_TABS,
    GET_TABS_SUCCESS,
    GET_LOGS_PATIENTS,
    GET_LOGS_PATIENTS_SUCCESS,
    CHANGE_MAIN_TAB,
    CHANGE_TAB,
    CREATE_LOG_SUCCESS,
    UPDATE_LOG_UNITS,
    UPDATE_LOG_SUCCESS,
    DELETE_LOG_SUCCESS,
    CHANGE_SEARCH,
    HANDLE_FAILURE
  } from '../actions/types';
  
  const initialState = {
    tabs: [],
    mainTab: 1,
    selectedTab: 1,
    selectedTabName: 'meals',
    search: '',
    updateSuccess: false,
    dailyLogs: {},
    loading: false,
    error: {},
    activeLog: {}
  };
  
  export default (state = initialState, action) => {
    switch(action.type) {
      case GET_TABS_SUCCESS:
        return {
          ...state,
          tabs: action.payload,
          loading: false,
          error: {}
        }
        case CHANGE_MAIN_TAB:
            return {
                ...state,
                mainTab: action.payload
            };
        case CHANGE_TAB:
            return {
              ...state,
              selectedTab: action.payload.tab,
              selectedTabName: action.payload.name,
              updateSuccess: true
            }
        case HANDLE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload,
          }
        case GET_TABS:
          return {
            ...state,
            loading: true,
          }
        case GET_LOGS_PATIENTS:
        return {
          ...state,
          loading: true,
        }
        case GET_LOGS_PATIENTS_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                dailyLogs: action.payload.dailyLogs,
                activeLog: action.payload.activeLog,
            }
        case UPDATE_LOG_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
            }
        case DELETE_LOG_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                dailyLogs: state.dailyLogs.filter(daily => (
                    daily === action.payload
                ))
            };
        case UPDATE_LOG_UNITS:
            const log = action.payload;

            return {
                ...state,
                dailyLogs: {
                    ...state.dailyLogs,
                    [log.patientId]: {
                        ...state.dailyLogs[log.patientId],
                        [log.type]: {
                            ...state.dailyLogs[log.patientId][log.type],
                            units: log.units
                        }
                    }
                }
            };
        case CREATE_LOG_SUCCESS:
            const newLog = action.payload;

            return {
                ...state,
                dailyLogs: {
                    ...state.dailyLogs,
                    [newLog.patientId]: {
                        ...state.dailyLogs[newLog.patientId],
                        [newLog.type]: {
                            ...state.dailyLogs[newLog.patientId][newLog.type],
                            id: newLog.id
                        }
                    }
                }
            };
        case CHANGE_SEARCH: 
            return {
                ...state,
                search: action.payload
            }
        default: 
          return {
            ...state
          }
    }
  }