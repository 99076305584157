import { GET_PATIENT_REFILLS, SET_REFILL_TO_SIGN} from './types';
import { getPatientRefills } from '../api/meds';

const getPatientsRefills = (URL, authHeaders, patientId) => dispatch => {
    getPatientRefills(URL, authHeaders, patientId)
        .then(refills => dispatch({
            type: GET_PATIENT_REFILLS,
            payload: refills.data.refills,
        }))
        .catch(err => {
            console.log(err);
        });
};

const setRefillToSign = (refill) => dispatch => {
  dispatch({
    type: SET_REFILL_TO_SIGN,
    payload: refill
  })
}


export {
  getPatientsRefills,
  setRefillToSign,
};