import routes from './constants';
import { createInstance } from './utils';

const medtechLogin = async(URL, email, password) => {
  const instance = createInstance(URL);
  return await instance.post(routes['MEDTECH_LOGIN'], { email, password });
};

const medtechLogout = async(URL, authHeaders) => {
    const instance = createInstance(URL, authHeaders);
    return await instance.delete(routes['MEDTECH_LOGOUT']);
};

const medtechData = async(URL, authHeaders) => {
  const instance = createInstance(URL, authHeaders);
  return await instance.get(routes['MEDTECH_DATA']);
};

const updateMedtechData = async(URL, authHeaders, signature) => {
  const instance = createInstance(URL, authHeaders);
  const body = {
    "user": {
      "default_signature": signature,
    }
  };
  return await instance.post(routes['MEDTECH_DATA'], body);
};

const getTenantSettings = async(URL, authHeaders) => {
  const instance = createInstance(URL, authHeaders);
  return await instance.get(routes['GET_SETTINGS']);
};

export {
    medtechLogin,
    medtechLogout,
    getTenantSettings,
    medtechData,
    updateMedtechData,
};