import routes from './constants';
import { createInstance } from './utils';

const dailyLogs = async(URL, authHeaders) => {
    const instance = createInstance(URL, authHeaders);
    return await instance.get(routes['DAILY_LOGS']);
};

const dailyLogsPatients = async(URL, authHeaders, id, search) => {
    const route = routes['DAILY_LOGS_PATIENTS'].replace('{dailyLogId}', id);
    const instance = createInstance(URL, authHeaders);
    return await instance.get(`${route}?search=${search}`);
};

const createPatientLog = async(URL, authHeaders, body) => {
    const instance = createInstance(URL, authHeaders);
    return await instance.post(routes['CREATE_DAILY_LOG'], body);
};

const updatePatientLog = async(URL, authHeaders, log) => {
    const route = routes['UPDATE_DAILY_LOG'].replace('{logEventId}', log.id);
    const instance = createInstance(URL, authHeaders);
    const body = {
        daily_log_event: {
            units: log.units
        }
      };
    return await instance.put(route, body);
};

const deletePatientLog = async(URL, authHeaders, event) => {
    const route = routes['DELETE_DAILY_LOG'].replace('{logEventId}', event.id);
    const instance = createInstance(URL, authHeaders);
    return await instance.delete(route);
};

export {
    dailyLogs,
    dailyLogsPatients,
    createPatientLog,
    updatePatientLog,
    deletePatientLog,
}