import React from  'react';
import Pen from '../../shared/images/pen.png';
import Narcotic from '../../shared/images/narcotic-icon.png';

const MedList = ({refills, setMed}) => {
  return(
    <>
      <section className="list-header">
        <div className="list-header-text-container-refills"><p className="list-header-text">DRUG NAME</p></div>
        <div className="list-header-text-container-refills"><p className="list-header-text">REFILL DATE</p></div>
        <div className="list-header-text-container-refills"><p className="list-header-text">DATE RANGE</p></div>
        <div className="list-header-text-container-refills"><p className="list-header-text">REFILL UNITS</p></div>
        <div className="list-header-text-container-refills"><p className="list-header-text">ACTIONS</p></div>
      </section>
      <div className="break"></div>
      <section className="list-content-container">
      {
        refills.map(refill => (
          <div className="list-content">
            <div className="large"><b>{refill.med_name}</b></div>
            <div className="large"><p>{refill.refill_date}</p></div>
            <div className="large"><p>{`${refill.start_date}->${refill.end_date}`}</p></div>
            <div className="large"><p>{refill.units}</p></div>
            <div className="small">
              <button className="refill-sign-btn" onClick={() => setMed(refill)}><img src={Pen} alt="icon" />sign</button>
            </div>
          </div>
        ))
      }
      </section>
    </>
  )
}

export default MedList;