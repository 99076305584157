import React from 'react';
import './headerStyles.css';
import Logo from '../../images/logo-behavioral-white.png';
import LockScreenLink from '../LockScreenLink/LockScreenLink';
import User from '../../images/ico-user.png';

const clear = (logOut, clearDomain, authHeaders, URL) => {
  logOut(URL, authHeaders);
  clearDomain();
  window.location.href = "/#/login";
}

const Header = ({ med_tech, logOut, clearDomain, settings, authHeaders, url }) => (
  <header className="header-container" style={{backgroundColor: `${settings.colors.primary_color1}`}}>
    <div className="header-content-container">
      <div className="content-space">
        <section id="logo" className="logo-container">
          <img className="h-img" src={ settings.logo } alt="logo" />
          <div className="residence-txt-container">
            <label style={{ textTransform: 'capitalize' }} className="residence-txt">{med_tech.building_name}</label>
          </div>
        </section>
        <section id="user" className="user-container">
          <div className="h-name">
            <label className="user-name">{med_tech.full_name}.</label>
            <label className="role">{med_tech.role.toUpperCase()}</label>
          </div>
          {/*<LockScreenLink />*/}
          <div className="link-container">
          <button className="log-out" style={{backgroundColor: `${ settings.colors.primary_color1 }`}} onClick={() => clear(logOut, clearDomain, authHeaders, url)}>
            <div className="h-oval"><img src={User} className="h-s" alt="user" /></div>
            <b className="h-lbl">Logout</b>
          </button>
          </div>
        </section>
      </div>
    </div>
  </header>
)

export default Header;