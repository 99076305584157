const MEDTECH_LOGIN = '/api/v1/auth/sign_in';
const MEDTECH_LOGOUT = '/api/v1/auth/sign_out';
const PATIENT_MEDS = '/api/v1/patients/{patientId}';
const PATIENT_NARCOTICS = '/api/v1/patients/{patientId}/narcotics';
const PATIENT_REFILLS = '/api/v1/patients/{patientId}/refills';
const SIGN_REFILL = '/api/v1/refills/{refillId}';
const PATIENTS = '/api/v1/patients';
const SIGN_MEDS = '/api/v1/med_events';
const SIGN_MEDS_AMEND = '/api/v1/amend_med_events';
const UPDATE_AVAILABLE_NARCOTIC = '/api/v1/meds/{medId}';
const GET_SETTINGS = '/api/v1/settings';
const WORKSPACES = '/y0Pgk34j6I.json';
const DIAGNOSES = '/api/v1/diagnoses';
const MEDTECH_DATA = '/api/v1/account';
const DAILY_LOGS  = '/api/v1/daily_logs';
const DAILY_LOGS_PATIENTS = '/api/v1/daily_logs/{dailyLogId}';
const CREATE_DAILY_LOG = '/api/v1/daily_logs/log_event';
const UPDATE_DAILY_LOG = '/api/v1/daily_logs/{logEventId}';
const DELETE_DAILY_LOG = '/api/v1/daily_logs/delete_log/{logEventId}';

const routes = {
  MEDTECH_LOGIN,
  MEDTECH_LOGOUT,
  PATIENT_MEDS,
  PATIENT_NARCOTICS,
  PATIENT_REFILLS,
  PATIENTS,
  SIGN_REFILL,
  SIGN_MEDS,
  UPDATE_AVAILABLE_NARCOTIC,
  SIGN_MEDS_AMEND,
  GET_SETTINGS,
  WORKSPACES,
  DIAGNOSES,
  MEDTECH_DATA,
  DAILY_LOGS,
  DAILY_LOGS_PATIENTS,
  CREATE_DAILY_LOG,
  UPDATE_DAILY_LOG,
  DELETE_DAILY_LOG,
};

export default routes;