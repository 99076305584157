import React from  'react';
import Pen from '../../shared/images/pen.png';

const MedList = ({ meds, activateModal, medsType, setNarcotic, formattedMedication }) => {
  return(
    <>
      <section className="sc-list-header">
        <div className="list-header-text-container-sc"><p className="list-header-text">DRUG PER DOSE</p></div>
        <div className="list-header-text-container-sc"><p className="list-header-text">DRUG NAME</p></div>
        <div className="list-header-text-container-sc"><p className="list-header-text">DRUG DOSE</p></div>
        <div className="list-header-text-container-sc"><p className="list-header-text">TYPE</p></div>
        <div className="list-header-text-container-sc"><p className="list-header-text">TAKE TIME</p></div>
      </section>
      <div className="break"></div>
      <section className="list-content-container">
      {
        meds.map(med => (
          <div className="list-content">
            <div className="list-header-text-container-sc"><label className="sc-med-text">{med.amount}</label></div>
            <div className="list-header-text-container-sc"><label className="sc-med-text">{med.name}</label></div>
            <div className="list-header-text-container-sc"><label className="sc-med-text">{med.dose}</label></div>
            <div className="list-header-text-container-sc"><label className="sc-med-text">{med.src_med_type}</label></div>
            <div className="list-header-text-container-sc"><label className="sc-med-text">{med.schedule}</label></div>
            { formattedMedication.some(formattedMed => (formattedMed.med_id === med.id && formattedMed.event_name === 'refusedPill'))
              ?
                <div className="s-t">
                  <div className="s-t-container">
                    <button onClick={() => activateModal(med)} className="l-btn">later</button>
                  </div>
                  {
                    medsType === 'narcotic' && (<div className="small-sign"><button className="refill-sign-btn" onClick={() => setNarcotic(med)}><img src={Pen} alt="icon" />sign</button></div>)
                  }
                </div>
              : 
                medsType === 'narcotic' && (<div className="small-sign"><button className="refill-sign-btn" onClick={() => setNarcotic(med)}><img src={Pen} alt="icon" />sign</button></div>)
            }
          </div>
        ))
      }
      </section>
    </>
  )
}

export default MedList;