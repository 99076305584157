import { 
  ACTIVATE_MODAL, 
  ACTIVATE_OTHER_MODAL, 
  DEACTIVATE_MODAL, 
  SET_MED_TO_SKIP, 
  SET_AS_NEEDED_MED, 
  DEACTIVATE_OTHER_MODAL, 
  SET_REASON_TO_SKIP, 
  SET_AS_NEEDED_REASON_TO_SKIP, 
  CLEAR_REASONS, 
  SET_LOCK_SCREEN_PASSWORD, 
  CLEAR_LOCK_SCREEN_PASSWORD, 
  ACTIVATE_LOCK_SCREEN, 
  SET_PAD_STYLE, 
  SET_AS_NEEDED_REASON_REMINDER_TIME,
  CLEAR_AS_NEEDED_REASONS_MODAL_DATA,
  SET_OTHER_PAD_STYLE,
  ACTIVATE_PATIENT_SIGNATURE,
  ACTIVATE_MEDTECH_SIGNATURE,
  OPTIONS_MODAL,
 } from '../actions/types';

const initialState = {
  active: false,
  otherActive: false,
  optionsActive: false,
  medToSkip: {},
  asNeeded: {},
  reasons: [null],
  asNeededReason: "",
  asNeededReasonReminderTime: "",
  lockScreenPassword: "",
  lockScreenActive: false,
  padStyle: "nrml-canvas",
  otherPadStyle: "other-nrml-canvas",
  patientSignatureModalActive: false,
  medtechSignatureModalActive: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case ACTIVATE_MODAL:
      return {
        ...state,
        active: action.payload,
      }
    case ACTIVATE_OTHER_MODAL:
      return {
        ...state,
        otherActive: action.payload,
      }
    case OPTIONS_MODAL:
      return {
        ...state,
        optionsActive: action.payload,
      }
    case DEACTIVATE_MODAL:
      return {
        ...state,
        active: action.payload,
      }
    case DEACTIVATE_OTHER_MODAL:
      return {
        ...state,
        otherActive: action.payload,
      }
    case SET_MED_TO_SKIP:
      return {
        ...state,
        medToSkip: action.payload,
      }
    case SET_REASON_TO_SKIP:
      return {
        ...state,
        reasons: [...state.reasons, action.payload],
      }
    case CLEAR_REASONS:
      return {
        ...state,
        reasons: [null],
      }
    case SET_AS_NEEDED_MED:
      return {
        ...state,
        asNeeded: action.payload,
      }
    case SET_AS_NEEDED_REASON_TO_SKIP:
      return {
        ...state,
        asNeededReason: action.payload,
      }
    case SET_AS_NEEDED_REASON_REMINDER_TIME:
      return {
        ...state,
        asNeededReasonReminderTime: action.payload,
      }
    case SET_LOCK_SCREEN_PASSWORD:
      return {
        ...state,
        lockScreenPassword: action.payload,
      }
    case CLEAR_LOCK_SCREEN_PASSWORD:
      return {
        ...state,
        lockScreenPassword: "",
      }
    case ACTIVATE_LOCK_SCREEN:
      return {
        ...state,
        lockScreenActive: action.payload,
      }
    case SET_PAD_STYLE:
      return {
        ...state,
        padStyle: action.payload,
      }
    case SET_OTHER_PAD_STYLE:
      return {
        ...state,
        otherPadStyle: action.payload,
      }
    case CLEAR_AS_NEEDED_REASONS_MODAL_DATA:
      return{
        ...state,
        asNeededReason: "",
        asNeededReasonReminderTime: "",
      }
    case ACTIVATE_PATIENT_SIGNATURE:
      return {
        ...state,
        patientSignatureModalActive: action.payload
      }
    case ACTIVATE_MEDTECH_SIGNATURE:
      return {
        ...state,
        medtechSignatureModalActive: action.payload
      }
    default:
      return state;
  }
}