import { SET_NARCOTIC_TO_SIGN, SET_FORMATTED_NARCOTIC, UPDATE_AVAILABLE_NARCOTIC_SUCCESS, SET_AVAILABLE_NARCOTIC_AMOUNT, SET_AMOUNT_TO_SEND } from '../actions/types';

const initialState = {
  narcoticToSign: {},
  formattedNarcotic: [],
  updatedAvailableNarcotic: false,
  amount: '',
  amountToSend: ''
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_NARCOTIC_TO_SIGN:
      return {
        ...state,
        narcoticToSign: action.payload
      }
    case SET_FORMATTED_NARCOTIC:
      return {
        ...state,
        formattedNarcotic: action.payload
      }
    case UPDATE_AVAILABLE_NARCOTIC_SUCCESS:
      return {
        ...state,
        updatedAvailableNarcotic: true,
        amount: action.payload
      }
    case SET_AVAILABLE_NARCOTIC_AMOUNT:
      return {
        ...state,
        amount: action.payload
      }
    case SET_AMOUNT_TO_SEND:
      return {
        ...state,
        amountToSend: action.payload
      }
    default:
      return state;
  }
}