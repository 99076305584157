import { GET_MEDS, CLEAR_COMPLETE } from './types';
import { getPatientMeds } from '../api/meds';

const getPatientsMedication = (URL, authHeaders, patientId, type, schedule) => dispatch => {
    const setAll = (meds) => {
      console.log('meds', meds)
      return [...meds.morning, ...meds.noon, ...meds.evening, ...meds.bedtime]
    };
    getPatientMeds(URL, authHeaders, patientId, type)
    .then(meds => dispatch({
        type: GET_MEDS,
        payload: schedule === 'all' ? setAll(meds.data.meds): meds.data.meds[schedule],
    }))
    .catch(err => {
        console.log(err);
    });
};

const clearComplete = () => dispatch => {
  dispatch({
    type: CLEAR_COMPLETE,
  })
};

export {
  getPatientsMedication,
  clearComplete,
};

