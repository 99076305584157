import { SET_NARCOTIC_TO_SIGN, SET_FORMATTED_NARCOTIC, UPDATE_AVAILABLE_NARCOTIC_SUCCESS, UPDATE_AVAILABLE_NARCOTIC_FAILED, SET_AVAILABLE_NARCOTIC_AMOUNT, SET_AMOUNT_TO_SEND } from './types';
import { updateNarcoticAvailableAmount } from '../api/meds';

const setNarcoticToSign = (narcotic) => dispatch => {
  dispatch({
    type: SET_NARCOTIC_TO_SIGN,
    payload: narcotic
  })
}

const setFormattedNarcotic = (narcotic) => dispatch => {
    dispatch({
      type: SET_FORMATTED_NARCOTIC,
      payload: narcotic
    })
  }

const updateAvailableNarcotics = (URL, authHeaders, medId, amount) => dispatch => {
  updateNarcoticAvailableAmount(URL, authHeaders, medId, amount)
    .then(response => {
      dispatch({
        type: UPDATE_AVAILABLE_NARCOTIC_SUCCESS,
        payload: response.data.med.available
      })
    })
    .catch(err => {
      dispatch({
        type: UPDATE_AVAILABLE_NARCOTIC_FAILED,
        payload: err
      })
    })
}

const setAvailableNarcoticAmount = (amount) => dispatch => {
  dispatch({
    type: SET_AVAILABLE_NARCOTIC_AMOUNT,
    payload: amount,
  })
}

const setAmountToSend = (amount) => dispatch =>{
  dispatch({
    type: SET_AMOUNT_TO_SEND,
    payload: amount
  })
}


export {
  setNarcoticToSign,
  setFormattedNarcotic,
  updateAvailableNarcotics,
  setAvailableNarcoticAmount,
  setAmountToSend,
};