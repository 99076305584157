const fullScreen = () => {
  var elem = document.documentElement;
  elem.webkitRequestFullscreen();
}

const exitFullScreen = () => {
  function closeFullscreen() {
    if (document.exitFullscreen) {
     document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
   } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
     document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
   }
  }
  closeFullscreen();
}

const blockPadButtons = () => {
  window.addEventListener('keydown', function(e) {
    if((e.shiftKey && e.keyCode === 109) || (e.ctrlKey && e.keyCode === 83)) {
        e.preventDefault();
        alert('blocking', e);
        return false;
      }
  })
  window.addEventListener("beforeunload", function (e) {
    var confirmationMessage = "o/";
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage;                            //Webkit, Safari, Chrome
  });
}

export { 
  fullScreen,
  exitFullScreen,
  blockPadButtons
};