/*Medication list child component for medication selection component*/

import React from  'react';
import Check from '../../shared/images/icon-check.png';
import X from '../../shared/images/icon-x-skip.png';
import Loader from '../../shared/components/Loader/Loader';
import Restore from '../../shared/images/icon-restore.png';
import Pen from '../../shared/images/pen.png';
import Narcotic from '../../shared/images/narcotic-icon.png';

const MedList = ({meds, selectMed, formattedSelectedMedication, restore, schedule, complete, setNarcotic}) => {
  return(
    <>
      <section className="list-header">
        <div className="list-header-text-container"><p className="list-header-text">DRUG PER DOSE</p></div>
        <div className="list-header-text-container"><p className="list-header-text">DRUG NAME</p></div>
        <div className="list-header-text-container"><p className="list-header-text">DRUG DOSE</p></div>
        <div className="list-header-text-container"><p className="list-header-text">TYPE</p></div>
        <div className="list-header-text-container"><p className="list-header-text">SCHEDULE</p></div>
        <div className="list-header-text-container"><p className="list-header-text">ACTIONS</p></div>
      </section>
      <div className="break"/>
      <section className="list-content-container">
      { complete
        ?
        meds.length > 0
          ?
          meds.map(med => (
            <div className={med.disabled ? "list-content dim": "list-content"}>
              <div className="t-small">{med.narcotic && <img src={Narcotic} alt="icon" style={{width: 30, marginRight: 10}} />}<label>{med.num_pills}</label></div>
              <div className="large"><label>{med.name}</label></div>
              <div className="t-small"><label>{med.dose}</label></div>
              <div className="t-small"><label>{med.src_med_type}</label></div>
              <div className="t-small"><label style={{ textTransform: 'capitalize' }}>{med.schedule}</label></div>
              { med.disabled
                ? 
                  <div className="s-t" />
                :
                  (<div className="s-t">
                    <div className="s-t-container">
                    { formattedSelectedMedication.some(formattedMed =>(formattedMed.med_id === med.id && formattedMed.event_name === 'takePill' && formattedMed.schedule === med.schedule ))
                        ? 
                          <>
                            <button className="s-btn" onClick={() => selectMed(med, "refusedPill")}><img className="s" src={X} alt="icon" />skip</button>
                            <button className="took-btn"><img src={Check} alt="icon" /></button>
                            {med.narcotic && (<div className="small-sign"><button className="refill-sign-btn" onClick={() => setNarcotic(med)}><img src={Pen} alt="icon" />sign</button></div>)}
                          </>
                        : 
                          formattedSelectedMedication.some(formattedMed => (formattedMed.med_id === med.id && formattedMed.event_name === 'refusedPill' && formattedMed.schedule === med.schedule ))
                            ? 
                              <>
                                <button className="restore-btn" onClick={() => restore(med)}><img src={Restore} /> restore</button>
                              </>
                            : 
                              <>
                                {med.schedule !== 'as_needed' && <button className="s-btn" onClick={() => selectMed(med, "refusedPill")}><img className="s" src={X} alt="icon" />skip</button>}
                                <button className="t-btn" onClick={() => selectMed(med, "takePill")}><img className="s" src={Check} alt="icon" />take</button>
                              </>
                    }
                    </div>
                  </div>)
              }
            </div>
          ))
          : <div className="not-found"><p>No medications found for {schedule} schedule</p></div>
        :<div className="loader-container"><Loader /></div>
      }
      </section>
    </>
  )
};

export default MedList;