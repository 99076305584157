import { SET_DOMAIN, GET_DOMAIN, CLEAR_DOMAIN, SET_DOMAIN_FLAG } from './types';

const setDomain = (domain) => dispatch => {
    dispatch({
        type: SET_DOMAIN,
        payload: domain,
    })
}

const getDomain = () => dispatch => {
  dispatch({
    type: GET_DOMAIN 
  })
}

const clearDomain = () => dispatch => {
  dispatch({
    type: CLEAR_DOMAIN,
  })
}

const setDomainFlag = () => dispatch => {
  dispatch({
    type: SET_DOMAIN_FLAG,
  })
}

export {
  setDomain,
  getDomain,
  clearDomain,
  setDomainFlag,
}