import moment from 'moment-timezone';

const getDate = () => {
    const year = moment().tz('America/Los_Angeles').year();
    const month = moment().tz('America/Los_Angeles').month() + 1;
    const day = moment().tz('America/Los_Angeles').date();
    console.log('this is the day', day)
    const hour = moment().tz('America/Los_Angeles').hour();
    const minutes = moment().tz('America/Los_Angeles').minutes();
    const seconds = moment().tz('America/Los_Angeles').seconds();
    const timeStamp = `${year}-${month}-${day} ${hour}:${minutes}:${seconds} -0800`;
    return timeStamp;
  }

  const getGreetings = () => {
    const hour = moment().tz('America/Los_Angeles').hour();
    if (hour < 12) {
      return 'Good morning!';
    }
  
    if (hour >= 12 && hour <= 18) {
      return 'Good afternoon!';
    }
  
    return 'Good evening!';
  };

  function checkIfOnTime(range, max) {
    //const hour = await axios.get('https://demo.arumhealth.com/api/v1/current_time');
    const hour = moment().tz('America/Los_Angeles').hour();
    const onTime = (hour <= max);
    return onTime;
  }
  
  function isOnTime(time) {
    const isAllowed = {
      as_needed: true,
      morning: checkIfOnTime('am', 9),
      noon: checkIfOnTime('noon', 14),
      evening: checkIfOnTime('afternoon', 23),
      bedtime: checkIfOnTime('bedtime', 23),
      all: true,
      take_daily: true
    };
    return isAllowed[time];
  }

  export { getDate, getGreetings, isOnTime };