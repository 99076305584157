import { 
  SET_STATUS, 
  SET_PATIENT_INFO,
  SET_MEDS,
  CLEAR_MEDICATION,
  SET_PATIENT_SIGNATURE,
  SET_MEDTECH_SIGNATURE,
  SET_MEDICATION_TYPE,
  SET_MEDICATION_SCHEDULE,
  SET_MEDTECH_INFO,
  SET_FORMATTED_MEDICATION,
  RESTORE_MED,
  RESTORE_FORMATTED_MED,
  SET_SCHEDULE_CHANGE,
  SET_SCHEDULE_CHANGE_FORMATTED,
  CLEAR_PATIENT_SESSION,
  DELETE_NARCOTIC_FROM_LIST,
  CLEAR_PARTIAL_SESSION,
  CLEAR_PATIENT_SIGNATURE,
  CLEAR_MEDTECH_SIGNATURE,
} from "./types";

const setStatus = (emoji) => dispatch => {
    dispatch({
        type: SET_STATUS,
        payload: emoji,
    });
};

const setPatientInfo = (patientData) => dispatch => {
  dispatch({
    type: SET_PATIENT_INFO,
    payload: patientData
  });
};

const setMedtechInfo = (medTechData) => dispatch => {
  dispatch({
    type: SET_MEDTECH_INFO,
    payload: medTechData
  });
};

const clearMedication = () => dispatch => {
  dispatch({
    type: CLEAR_MEDICATION,
  })
}

const setPatientFormattedMed = (med) => dispatch => {
    dispatch({
      type: SET_FORMATTED_MEDICATION,
      payload: med
    });
};

const setPatientMed = (med) => dispatch => {
  dispatch({
    type: SET_MEDS,
    payload: med
  });
};

const clearPatientSession = () => dispatch => {
  dispatch({
    type: CLEAR_PATIENT_SESSION,
  });
};

const clearPartialSession = () => dispatch => {
  dispatch({
    type: CLEAR_PARTIAL_SESSION,
  });
};

const restorePatientFormattedMed = (med, formattedMeds) => dispatch => {
  const newFormattedMeds = formattedMeds.filter(formattedMed => {
    return formattedMed.med_id === med.id  
            ? formattedMed.med_id === med.id && formattedMed.schedule !== med.schedule
            : formattedMed.med_id !== med.id
  })
  dispatch({
    type: RESTORE_FORMATTED_MED,
    payload: newFormattedMeds
  });
};

const restorePatientMed = (med, meds) => dispatch => {
  const newFormattedMeds = meds.filter(medication => {
    return medication.id === med.id 
            ? medication.id === med.id && medication.schedule !== med.schedule
            : medication.id !== med.id
  })
  dispatch({
    type: RESTORE_MED,
    payload: newFormattedMeds
  });
};

const changeMedSchedule = (medication, med, schedule) => dispatch => {
  const newMedication = medication.map(eMed =>{
    if (eMed.id === med.id && eMed.schedule === med.schedule) {
      eMed.schedule = schedule
    }
    return eMed;
  })
  console.log(newMedication)
  dispatch({
    type: SET_SCHEDULE_CHANGE,
    payload: newMedication
  })
}

const changeFormattedMedSchedule = (formattedMedication, med, schedule) => dispatch => {
  const newMedication = formattedMedication.map(eMed =>{
    if (eMed.med_id === med.id && eMed.schedule === med.schedule) {
      if (eMed.event_name === 'refusedPill') {
        eMed.event_name = 'postponePill';
        eMed.postponed_until = schedule;
      } else {
        med.event_name = 'refusedPill';
        med.postponed_until = '';
      }
    }
    return eMed;
  })
  dispatch({
    type: SET_SCHEDULE_CHANGE_FORMATTED,
    payload: newMedication
  })
}

const setPatientSignature = (patientSignature) => dispatch => {
    dispatch({
      type: SET_PATIENT_SIGNATURE,
      payload: patientSignature
    });
};

const setMedtechSignature = (medtechSignature) => dispatch => {
  dispatch({
    type: SET_MEDTECH_SIGNATURE,
    payload: medtechSignature
  });
};

const clearPatientSignature = () => dispatch => {
  dispatch({
    type: CLEAR_PATIENT_SIGNATURE,
  })
}

const clearMedTechSignature = () => dispatch => {
  dispatch({
    type: CLEAR_MEDTECH_SIGNATURE,
  })
}

const setPatientMedsType = (type) => dispatch => {
    dispatch({
      type: SET_MEDICATION_TYPE,
      payload: type
    });
};

const setPatientMedsSchedule = (schedule) => dispatch => {
  dispatch({
    type: SET_MEDICATION_SCHEDULE,
    payload: schedule
  });
};

const deleteNarcoticFromList = (narcotic, medList) => dispatch => {
  const newList = medList.filter(med => (
    med.name !== narcotic.name
  ))
  dispatch({
    type: DELETE_NARCOTIC_FROM_LIST,
    payload: newList
  })
}

export {
  setStatus,
  setPatientInfo,
  setPatientMed,
  setPatientSignature,
  setMedtechSignature,
  setPatientMedsType,
  setPatientMedsSchedule,
  setMedtechInfo,
  setPatientFormattedMed,
  restorePatientMed,
  restorePatientFormattedMed,
  changeMedSchedule,
  changeFormattedMedSchedule,
  clearPatientSession,
  deleteNarcoticFromList,
  clearPartialSession,
  clearMedication,
  clearMedTechSignature,
  clearPatientSignature,
};