import { 
  LOGOUT,
  SET_DOMAIN,
  SET_USER, 
  SET_PASSWORD, 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE, 
  ACTIVATE_LOGIN_ALERT,
  GET_MEDTECH_DATA,
  UPDATE_MEDTECH_DATA,
  UPDATE_MEDTECH_DATA_SUCCESS,
  UPDATE_MEDTECH_DATA_FAILURE,
} from './types';
import { 
  medtechLogin, 
  medtechLogout,
  medtechData,
  updateMedtechData 
} from '../api/login';

const login = (URL, user, password) => dispatch => {
    medtechLogin(URL, user, password)
    .then(response => {
        const { data, headers} = response;

        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                data: data.data,
                headers: headers
            }
        });
    })
    .catch(err => {
      dispatch({
        type: LOGIN_FAILURE
      });
    });
};

const setAuth = (userData, headers) => dispatch => {
    dispatch({
        type: LOGIN_SUCCESS,
        payload: {
            data: userData,
            headers: headers
        }
    });
};

const getMedtechData = (URL, authHeaders) => dispatch => {
  return medtechData(URL, authHeaders)
  .then(response => {
    return dispatch({
      type: GET_MEDTECH_DATA,
      payload: response.data,
    })
  })
};

const updateMedtechInfo = (URL, authHeaders, signature) => dispatch => {
  dispatch({type:UPDATE_MEDTECH_DATA});

  updateMedtechData(URL, authHeaders, signature)
      .then(response => {
        dispatch({
          type: UPDATE_MEDTECH_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({type: UPDATE_MEDTECH_DATA_FAILURE});
      })
}

const activateLoginAlert = (boolean) => dispatch => {
  dispatch({
    type: ACTIVATE_LOGIN_ALERT,
    payload: boolean,
  })
}

const setDomain = (domain) => dispatch => {
    dispatch({
        type: SET_DOMAIN,
        payload: domain,
    })
}

const setUser = (user) => dispatch => {
    dispatch({
        type: SET_USER,
        payload: user,
    })
}

const setPassword = (password) => dispatch => {
    dispatch({
        type: SET_PASSWORD,
        payload: password,
    })
}

const logOut = (URL, authHeaders) => dispatch => {
    medtechLogout(URL, authHeaders)
        .then(() => {
            dispatch({ type: LOGOUT })
        })
        .catch(error => {
            console.log(error);
        })
};

export {
  login,
  logOut,
  setDomain,
  setUser,
  setPassword,
  activateLoginAlert,
  getMedtechData,
  updateMedtechInfo,
    setAuth
};