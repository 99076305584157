import React from 'react';
import Button from '../Button/Button';
import './buttonsStyles.css';
import Pen from '../../images/pen.png';

const renderButtons = (amount, options, type) => {
  if(type === 'reasons') {
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" onClick={options.modalBack} />
        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.modalBack} />
        <Button type="continue" text="continue" onClick={options.modalContinue} />
      </div>
      )
    } else {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" />
          <Button type="other" text="Other" />
          <Button type="continue" text="Continue" />

        </div> 
      )
    }

  }else if(type === 'signature') {
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" onClick={options.back} />
        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.back} />
        <Button type="continue" text="confirm" onClick={options.continue} />
      </div>
      )
    } else {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" />
          <Button type="other" text="Other" />
          <Button type="continue" text="Continue" />

        </div> 
      )
    }

  } else if(type === 'confirm') {
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" onClick={options.back} />
        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.back} />
        <Button type="signatures" text="signatures" onClick={options.goTo}><img src={Pen} alt="icon" /></Button>
      </div>
      )
    } else if(amount === 3) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="back" onClick={options.back} />
          <Button type="signatures" text="signatures" onClick={options.goTo}><img src={Pen} alt="icon" /></Button>
          <Button type="continue" text="continue" onClick={options.continue} />
         </div>
      )
    }

  } else if(type === 'schedule') {
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="continue" text="Continue" onClick={options.modalContinue} />
        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.modalBack} />
        <Button type="continue" text="continue" onClick={options.modalContinue} />
      </div>
      )
    } else {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" />
          <Button type="other" text="Other" />
          <Button type="continue" text="Continue" />

        </div> 
      )
    }
  } else if(type === 'amend'){
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" onClick={options.back} />
          <Button type="continue" text="Amendment" onClick={options.amend} />
        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.back} />
        <Button type="continue" text="continue" onClick={options.continue} />
      </div>
      )
    } else {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" />
          <Button type="other" text="Other" />
          <Button type="continue" text="Continue" />

        </div> 
      )
    }
  }else{
    if(amount === 1) {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" onClick={options.back} />

        </div>
      )
    } else if(amount === 2) {
      return(
      <div className="bottom-buttons-container">
        <Button type="back" text="back" onClick={options.back} />
        <Button type="continue" text="continue" onClick={options.continue} />
      </div>
      )
    } else {
      return(
        <div className="bottom-buttons-container">
          <Button type="back" text="Back" />
          <Button type="other" text="Other" />
          <Button type="continue" text="Continue" />

        </div> 
      )
    }
  }
}

const Buttons = ({amount, options, type }) => {
  return(
    <>
    {renderButtons(amount, options, type)}
    </>
  );
}

export default Buttons;