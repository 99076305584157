import React from 'react';
import './modalStyles.css';

const Modal = ({style, children, active}) => ((
  <>
    { active
      ?
  <section className="modal-background">
    {style === undefined ?<>{children}</> : <div className={style}>{children}</div>}
  </section>
    :null
    }
  </>
));

export default Modal;