import React, {Component} from 'react';
import Routes from '../routes';
import { blockApp } from '../actions/blockActions';
import { connect } from 'react-redux';

class App extends Component {
  render() {
    const { loggedIn, domainSet } = this.props;
    return (
      <Routes loggedIn={loggedIn} domainSet={domainSet} />
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  domainSet: state.domain.domainSet,
})

export default connect(mapStateToProps, { blockApp })(App);
