import React from 'react';
import { getGreetings } from '../../utils';
import './statusBarStyles.css';
import Sad from '../../images/sad-mini.png';
import Mad from '../../images/bad-mini.png';
import Happy from '../../images/happy-mini.png';
import Anxious from '../../images/anxious-mini.png';
import Neutral from '../../images/neutral-mini.png';
import Avatar from '../../images/ico-avatar.png';
import Emoji from '../Emoji/Emoji';

const StatusBar = ({ patient, setPatientStatus, status, medicationType }) => (
  <div className={medicationType !== 'refill' ? "status-bar-container" : "status-bar-container-refill"}>
    <div className="patient-img-container">
      <div className="patient-img-sb">
        <img className="sb-avatar" src={Avatar} alt="avatar" />
      </div>
    </div>
    <div className="patient-name-container">
      <div><label className="salute-text">{getGreetings()}</label></div>
      <div><label className="sb-name-text">{`${patient.name} ${String(patient.last_name).charAt(0)}`}</label></div>
    </div>
    {
      medicationType !== 'refill' && 
        (<section className="patient-status-container">
          <div className="emojis-container">
              <Emoji type={ status === 'Mad' ? "active-emoji" : "emoji-container" } option="Bad" text="Mad" select={() => setPatientStatus('Mad')} />
              <Emoji type={ status === 'Anxious' ? "active-emoji" : "emoji-container" } option="Anxious" text="Anxious" select={() => setPatientStatus('Anxious')} />
              <Emoji type={ status === 'Sad' ? "active-emoji" : "emoji-container" } option="NeutralBad" text="Sad" select={() => setPatientStatus('Sad')} />
              <Emoji type={ status === 'Neutral' ? "active-emoji" : "emoji-container" } option="NeutralHappy" text="Neutral" select={() => setPatientStatus('Neutral')} />
              <Emoji type={ status === 'Happy' ? "active-emoji" : "emoji-container" } option="Happy" text="Happy" select={() => setPatientStatus('Happy')} />
          </div>
        </section>)
    }
    <div></div>
  </div>
);

export default StatusBar;