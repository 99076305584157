/*Component not being used due to the unification of the UI with dashboard*/

import React, {Component} from 'react';
import Header from '../../shared/components/Header/Header';
import ContentCard from '../../shared/components/ContentCard/ContentCard';
import StatusBar from '../../shared/components/StatusBar/StatusBar';
import MedList from './MedList';
import Buttons from '../../shared/components/Buttons/Buttons';
import Modal from '../../shared/components/Modal/Modal';
import Regular from '../../shared/images/btn_medication.png';
import Narcotic from '../../shared/images/btn_narcotic.png';
import Refill from '../../shared/images/btn_refill.png';
import Button from '../../shared/components/Button/Button';
import { getDate } from '../../shared/utils';
import { activateModal, activateOtherModal, deactivateModal, deactivateOtherModal } from '../../actions/modalActions';
import { setScheduleChange, setScheduleChangeMed } from '../../actions/scheduleChangeActions';
import { setNarcoticToSign, setFormattedNarcotic } from '../../actions/narcoticActions';
import { changeMedSchedule, changeFormattedMedSchedule, setPatientMedsType, clearPartialSession, clearPatientSession } from '../../actions/patientSessionActions';
import { logOut } from '../../actions/loginActions';
import { clearDomain } from '../../actions/domainActions';
import { connect } from 'react-redux';
import '../MedicationSelection/medicationSelectionStyles.css';

class ScheduleConfirmation extends Component {
  
  back = () => {
    this.props.history.push('/medication_selection');
  }

  continue = () => {
    this.props.history.push('/medication_signing');
  }

  activateModal = (med) => {
    this.props.setScheduleChangeMed(med);
    this.props.activateModal(true);
  }

  modalBack = () => {
    this.props.deactivateModal(false);
  }

  modalContinue = () => {
    const { scheduleChanged, selectedMedication, med, formattedMedication } = this.props;
    this.props.changeMedSchedule(selectedMedication, med, scheduleChanged);
    this.props.changeFormattedMedSchedule(formattedMedication, med, scheduleChanged)
    this.props.deactivateModal(false);
  }

  setNarcoticMed = (med) => {
    const  formattedNarcotic  = this.formatMed(med, 'takePill');
    this.props.setNarcoticToSign(med);
    this.props.setFormattedNarcotic(formattedNarcotic)
    this.props.history.push('/medication_signing');
  }

  formatMed = (med, type) => {
    const formattedMed = {
      "patient_id": med.patient_id,
      "med_id": med.id,
      "event_name": type,
      "event_date": getDate() ,
      "postponed_until": '',
      "symtoms": "",
      "notes": "",
      "schedule": med.schedule,
    }
    return formattedMed
  }

  setOption = (type) => {
    const { status } = this.props;
    this.props.clearPartialSession();
    this.props.setPatientMedsType(type);
    this.props.deactivateOtherModal(false);
    type === 'refill' ? this.props.history.push('/refills') : status.length > 0 ? this.props.history.push('/schedule'): this.props.history.push('/confirm') ;
  }

  renderOptions = () => {
    const { patient, medTech } = this.props;
    const { data: medTechData } = medTech;
    const { building_certification } = medTechData
    if(building_certification === 'default') {
      if(patient.takes_narcotic && patient.pending_refills) {
        return (
          <div className="options-container">
            <Button type="option" onClick={() => this.setOption("regular")}>
              <img src={Regular} alt="icon" />
            </Button>
            <Button type="option-narcotic" onClick={() => this.setOption("narcotic")}>
              <img src={Narcotic} alt="icon" />
            </Button>
            <Button type="option-refill" onClick={() => this.setOption("refill")}>
              <img src={Refill} alt="icon" />
            </Button>
          </div>
        )
      } else if(patient.takes_narcotic && patient.pending_refills===false) {
        return (
            <div className="options-container">
              <Button type="option" onClick={() => this.setOption("regular")}>
                <img src={Regular} alt="icon" />
              </Button>
              <Button type="option-narcotic" onClick={() => this.setOption("narcotic")}>
                <img src={Narcotic} alt="icon" />
              </Button>
            </div>
          )
      } else if(patient.pending_refills) {
        return (
            <div className="rs-options-container">
              <Button type="option" onClick={() => this.setOption("regular")}>
                <img src={Regular} alt="meds icon" />
                <label>select</label>
              </Button>
              <Button type="option-refill" onClick={() => this.setOption("refill")}>
                <img src={Refill} alt="refill icon" />
                <label>refill</label>
              </Button>
            </div>
          )
      } else {
        return (
            <div className="options-container">
              <Button type="option" onClick={() => this.setOption("regular")}>
                <img src={Regular} alt="icon" />
              </Button>
            </div>
          )
      }
    } else {
      if(patient.takes_narcotic) {
        return (
          <div className="options-container">
            <Button type="option" onClick={() => this.setOption("regular")}>
              <img src={Regular} alt="icon" />
            </Button>
            <Button type="option-narcotic" onClick={() => this.setOption("narcotic")}>
              <img src={Narcotic} alt="icon" />
            </Button>
          </div>
        )
      } else {
        return (
            <div className="options-container">
              <Button type="option" onClick={() => this.setOption("regular")}>
                <img src={Regular} alt="icon" />
              </Button>
            </div>
          )
      }
    }
  };

  exit = () => {
    this.props.clearPatientSession();
    this.props.deactivateOtherModal(false);
    this.props.history.push('/lock');
  }
  
  componentDidMount() {
    const { medicationType, selectedMedication } = this.props;
    if(medicationType === 'narcotic' && selectedMedication.length === 0) {
      this.props.activateOtherModal(true);
    } 
  }

  render() {
    const { 
      selectedMedication, 
      active, 
      otherActive, 
      setScheduleChange, 
      medicationType, 
      patient, 
      medTech, 
      status, 
      logOut, 
      clearDomain, 
      schedule,
      formattedMedication,
      settings
    } = this.props;
    const { data: medTechData } = medTech;
    return(
      <>
        <Header med_tech={medTechData} logOut={logOut} clearDomain={clearDomain} settings={settings} />
        <ContentCard>
          <StatusBar step="4" patient={patient} status={status} medicationType={medicationType} schedule={schedule} />
          <div className="status-title-container">
              <div className="status-title-line"></div>
                <div className="status-title-text-container">
                    <p className="status-title-text">Confirm medication schedule</p>
                </div>
              <div className="status-title-line"></div>
            </div>
          <MedList meds={selectedMedication} formattedMedication={formattedMedication} activateModal={this.activateModal} medsType={medicationType} setNarcotic={this.setNarcoticMed} />
          <div className="break" />
          <Buttons amount={medicationType === 'narcotic' ? 1 : 2} options={{back: this.back, continue: this.continue}} />
        </ContentCard>
        <Modal style="schedule" active={active}>
          <div>
            <div><p className="s-title">Select time</p></div>
            <div className="break" />
            <div className="s-btn-container">
              <button onClick={() => setScheduleChange("morning")} className="schedule-btn">morning</button>
              <button onClick={() => setScheduleChange("noon")} className="schedule-btn">Noon</button>
              <button onClick={() => setScheduleChange("evening")} className="schedule-btn">Evening</button>
              <button onClick={() => setScheduleChange("bedtime")} className="schedule-btn">Bedtime</button>
            </div>
            <div className="break" />
            <Buttons type="schedule" amount={1} options={{modalBack: this.modalBack, modalContinue: this.modalContinue}} />
          </div>
        </Modal>
        <Modal style="options" active={otherActive}>
          <div className="options-cont">
            <div className="options-name-container">
              <p className="options-name-txt">{`${patient.name} ${patient.last_name}`}</p>
            </div>
            <div className="options-txt-container">
              <p className="options-txt">Would you like to continue with another process?</p>
            </div>
            {
              this.renderOptions()
            }
            <div className="break" style={{marginTop: 37, marginBottom: 37}} />
            <div className="options-exit-container">
              <p className="or-txt">Or</p>
              <Button type="exit" text="EXIT" onClick={this.exit} />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  selectedMedication: state.patientSession.medication,
  active: state.modal.active,
  otherActive: state.modal.otherActive,
  scheduleChanged: state.schedule_change.schedule,
  med: state.schedule_change.med,
  formattedMedication: state.patientSession.formattedMedication,
  medicationType: state.patientSession.medicationType,
  patient: state.patientSession.patient,
  status: state.patientSession.status,
  medTech: state.patientSession.med_tech,
  schedule: state.patientSession.schedule,
  settings: state.settings,
  domain: state.domain.domain,
});

export default connect(mapStateToProps, { activateModal, deactivateModal, setScheduleChange, changeMedSchedule, changeFormattedMedSchedule, setScheduleChangeMed, setNarcoticToSign, setFormattedNarcotic, activateOtherModal, deactivateOtherModal, setPatientMedsType, clearPartialSession, clearPatientSession, logOut, clearDomain })(ScheduleConfirmation);