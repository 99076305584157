import { combineReducers } from 'redux';
import loginReducer from './loginReducer';
import medsReducer from './medsReducer';
import patientsReducer from './patientsReducer';
import domainReducer from './domainReducer';
import emojiReducer from './emojiReducer';
import patientSessionReducer from './patientSessionReducer';
import modalReducer from './modalReducer';
import scheduleChangeReducer from './scheduleChangeReducer';
import refillsReducer from './refillsReducer';
import medeventReducer from './medeventReducer';
import narcoticReducer from './narcoticReducer';
import amendReducer from './amendReducer';
import lockReducer from './blockReducer';
import settingsReducer from './settingsReducer';
import workspacesReducer from './workspacesReducer';
import covidReducer from './covidReducer';
import logsReducer from './logsReducer';
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
    login: loginReducer,
    meds: medsReducer,
    patients: patientsReducer,
    domain: domainReducer,
    emoji: emojiReducer,
    patientSession: patientSessionReducer,
    modal: modalReducer,
    schedule_change: scheduleChangeReducer,
    refills: refillsReducer,
    med_event: medeventReducer,
    narcotic: narcoticReducer,
    amend: amendReducer,
    lock: lockReducer,
    settings: settingsReducer,
    workspaces: workspacesReducer,
    corona: covidReducer,
    logs: logsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        storage.removeItem('persist:root-admin')
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;
