import {
  SET_ACCOUNT,
  SET_USER, 
  SET_PASSWORD, 
  LOGIN_SUCCESS, 
  LOGIN_FAILURE,
  ACTIVATE_LOGIN_ALERT,
  GET_MEDTECH_DATA,
  UPDATE_MEDTECH_DATA_SUCCESS,
  UPDATE_MEDTECH_DATA_FAILURE,
  UPDATE_MEDTECH_DATA,
} from '../actions/types';

const initialState = {
  account: "",
  password: "",
  user: "",
  loginResponse: {},
  updatingSuccess: false,
  error: {},
  loggedIn: false,
  alert: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginResponse: action.payload,
        loggedIn: action.type
      }
    case GET_MEDTECH_DATA:
      return {
        ...state,
        loginResponse: action.payload,
        loggedIn: true,
        updatingSuccess: false,
      }
    case UPDATE_MEDTECH_DATA:
      return {
        ...state,
        updatingSuccess: false,
      }
    case UPDATE_MEDTECH_DATA_SUCCESS:
      return {
        ...state,
        updatingSuccess: true
      }
    case UPDATE_MEDTECH_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        updatingSuccess: false
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: action.type
      }
    case ACTIVATE_LOGIN_ALERT:
      return {
        ...state,
        alert: action.payload,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload
      }
    case SET_PASSWORD:
      return {
        ...state,
        password: action.payload
      }
    default:
      return state;
  }
}