/*
This component is now the home main component for the unified UI.
This is where med medication adherence and Daily logs tabs are implemented
*/
import React, {Component} from 'react';
import Header from '../../shared/components/Header/Header';
import ContentCard from '../../shared/components/ContentCard/ContentCard';
import Input from '../../shared/components/Input/Input';
import List from './List';
import { connect } from 'react-redux';
import { getResidencePatients } from '../../actions/patientsActions';
import {
    setPatientMedsType,
    setPatientInfo,
    setMedtechInfo,
    setMedtechSignature,
    clearMedTechSignature,
    clearPatientSignature
} from '../../actions/patientSessionActions';
import {
    dailyLogsTabs,
    getDailyLogsPatients,
    changeMainTab,
    changeTab,
    createDailyLog,
    updateDailyLog,
    updateDailyLogUnits,
    deleteDailyLog,
    changeSearch
} from '../../actions/logsActions';
import {
    setPadStyle,
    setOtherPadStyle,
    activatePatientSignature,
    activateMedtechSignature,
} from '../../actions/modalActions';
import { getSettings } from '../../actions/settingsActions';
import {
    logOut,
    getMedtechData,
    updateMedtechInfo,
    activateLoginAlert,
    setAuth
} from '../../actions/loginActions';
import { clearDomain, setDomain, setDomainFlag } from '../../actions/domainActions';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../shared/components/Loader/Loader';
import Avatar from '../../shared/images/ico-avatar.png';
import Morning from '../../shared/images/icons-morning-color.png';
import Noon from '../../shared/images/icon-noon-color.png';
import Evening from '../../shared/images/icons-evening-color.png';
import Bedtime from '../../shared/images/icon-bed-time-color.png';
import Signature from '../../shared/components/Signature/Signature';
import  Modal from '../../shared/components/Modal/Modal';
import './residentSearchStyles.css';

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;

library.add(faSearch);

let medtechPad = {};
let unitsDelayTimer;
let searchDelayTimer;

class ResidentSearch extends Component {

    componentDidMount() {
        const { loginResponse, domain, selectedTab, search } = this.props;
        const { headers } = loginResponse;

        let searchQuery = this.props.location.search;
        let params = new URLSearchParams(searchQuery);
        let urlToken = params.get('access-token');
        let urlClient = params.get('client');
        let urlUid = params.get("uid");
        let url_domain = params.get('tenant_name');

        if (urlToken === null) {
            this.renderPatients()
                .then(() => {
                    this.renderDailyLogsTabs();
                    this.showDailyLogsIfIsDailyLogsRole(loginResponse.data);
                    this.props.getDailyLogsPatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, selectedTab === undefined ? 1 : selectedTab, search === undefined ? '' : search);
                })
                .catch(console.log);
        } else {
            let showDailyLogsIfIsDailyLogsRole = this.showDailyLogsIfIsDailyLogsRole;
            let props = this.props;

            const authHeaders = {
                "access-token": urlToken,
                "client": urlClient,
                "uid": urlUid
            };

            this.props.getMedtechData(`https://${url_domain}.${DOMAIN_EXTENSION}`, authHeaders)
                .then(function (response) {
                    let medTech = response.payload;
                    props.setAuth(medTech.user, authHeaders);
                    showDailyLogsIfIsDailyLogsRole(medTech.user);
                    props.setDomain(url_domain);
                    props.setDomainFlag();
                    props.activateLoginAlert(false);
                    props.getSettings(`https://${url_domain}.${DOMAIN_EXTENSION}`, authHeaders);
                    props.getResidencePatients(`https://${url_domain}.${DOMAIN_EXTENSION}`, authHeaders, search);
                    props.getDailyLogsPatients(`https://${url_domain}.${DOMAIN_EXTENSION}`, authHeaders, selectedTab === undefined ? 1 : selectedTab, search === undefined ? '' : search);
                    props.dailyLogsTabs(`https://${url_domain}.${DOMAIN_EXTENSION}`, authHeaders);
                })
                .catch(() => {
                    props.activateLoginAlert(true);
                    props.history.push('/');
                });
        }
    }

    showDailyLogsIfIsDailyLogsRole = (user) => {
        if (user && user.role === "daily_log") {
            this.changeMainTab(2);
        }
    };

    setSearchQuery = e => {
        this.props.changeSearch(e.target.value);
    };

    renderPatients = () => {
        const { loginResponse, domain, search } = this.props;
        const { headers } = loginResponse;
        return this.props.getResidencePatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, search);
    }

    renderDailyLogsTabs = () => {
        const { loginResponse, domain} = this.props;
        const { headers } = loginResponse;
        this.props.dailyLogsTabs(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
    }

    setOption = (type, patientData) => {
        const { loginResponse } = this.props;
        this.props.setPatientMedsType(type);
        this.props.setPatientInfo(patientData);
        this.props.setMedtechInfo(loginResponse)
        type === 'refill' ? this.props.history.push('/refills') : this.props.history.push('/confirm');
    }

    switchPage = (page) => {
        const { loginResponse, domain } = this.props;
        const { headers } = loginResponse;
        this.props.getResidencePatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, "", page);
    }

    renderPagination = () => {
        const { patients } = this.props;
        const { meta } = patients;
        let pages = [];
        for(var i = 1; i <= meta.last_page; i++ ) {
            pages.push(<button value={i} onClick={(e) => this.switchPage(e.target.value)} className={meta.current_page === i ? "active-page": "page"}>{i}</button>)
        }
        return pages;
    }

    getLogsPatients = (id, name) => {
        this.props.changeTab(id, name)
    }

    changeMainTab = (value) => {
        this.props.changeMainTab(value)
    };

    componentDidUpdate(prevProps, prevState) {
        const { loginResponse, domain, selectedTab, search, mainTab } = this.props;
        const { headers } = loginResponse;

        clearTimeout(searchDelayTimer);

        const props = this.props;
        const renderPatients = this.renderPatients;

        searchDelayTimer = setTimeout(function() {
            if (prevProps.search !== search || (search === "" && Object.keys(props.dailyLogs).length === 0)) {
                renderPatients();
                props.getDailyLogsPatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, selectedTab, search);
            }
        }, 500);

        if (prevProps.selectedTab !== selectedTab) {
            console.log('updating patients after tab change');
            this.props.getDailyLogsPatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, selectedTab, search);
        }

        if(this.props.updatingSuccess === true) {
            this.props.getMedtechData(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
            this.props.activateMedtechSignature(false);
        }

        if(loginResponse.hasOwnProperty('headers') &&
            loginResponse.hasOwnProperty('access-token') &&
            loginResponse.data.building_certification !== 'default' &&
            loginResponse.data.latest_signature === null) {

            console.log('no signature saved')
            this.props.activateMedtechSignature(true)
        }
    }

    setSignature = (signature, type) => {
        const { loginResponse } = this.props;
        console.log('setting ', type, 'signature')
        type === 'patient' ? this.props.setPatientSignature(signature): loginResponse.data.latest_signature === null ? this.updateMedtech() : this.props.setMedtechSignature(signature);
    }

    clearSignature = (type) => {
        type === 'patient'
            ? this.props.clearPatientSignature()
            : this.props.clearMedTechSignature();
    }

    closeModal = (type) => {
        type === 'patient'
            ? this.props.activatePatientSignature(false)
            : this.props.activateMedtechSignature(false);
    }

    insertLog = (eventState, value, e) => {
        const { loginResponse, domain } = this.props;
        const { headers } = loginResponse;

        if (e.target.type === 'checkbox') {
            const log = {
                ...eventState,
                units: e.target.checked ? "1" : "0"
            };

            this.props.updateDailyLogUnits(log);

            let props = this.props;

            if (log.id === null) {
                props.createDailyLog(`https://${domain}.${DOMAIN_EXTENSION}`, headers, log);
            } else {
                props.updateDailyLog(`https://${domain}.${DOMAIN_EXTENSION}`, headers, log)
            }
        } else {
            const log = {
                ...eventState,
                units: value
            };

            this.props.updateDailyLogUnits(log);

            clearTimeout(unitsDelayTimer);

            let props = this.props;

            unitsDelayTimer = setTimeout(function() {
                if (log.id === null) {
                    props.createDailyLog(`https://${domain}.${DOMAIN_EXTENSION}`, headers, log);
                } else {
                    props.updateDailyLog(`https://${domain}.${DOMAIN_EXTENSION}`, headers, log)
                }
            }, 500);
        }
    };

    updateMedtech = () => {
        const { medtechSignature, domain, loginResponse } = this.props;
        const { headers } = loginResponse;
        this.props.updateMedtechInfo(`https://${domain}.${DOMAIN_EXTENSION}`, headers, medtechSignature);
    }

    render(){
        const {
            domain,
            patients,
            logOut,
            clearDomain,
            settings,
            loginResponse,
            medtechSignatureModalActive,
            mainTab
        } = this.props;

        const { headers } = loginResponse;

        return(
            <>
                {
                    loginResponse.hasOwnProperty('headers') && headers.hasOwnProperty('access-token')
                        ?
                        <>
                            <Header
                                med_tech={ loginResponse.data }
                                logOut={ logOut }
                                clearDomain={ clearDomain }
                                settings={ settings }
                                authHeaders={ headers }
                                url={`https://${domain}.${DOMAIN_EXTENSION}`}
                            />

                            <ContentCard mainTab={mainTab} changeMainTab={this.changeMainTab} medTech={loginResponse.data}>
                                <div className="input-container">
                                    <Input placeholder="Please enter name of the resident" type="search" name="search" value={this.props.search} handle={this.setSearchQuery} />
                                    <FontAwesomeIcon icon="search" size="2x" className="search-icon" />
                                </div>
                                {
                                    mainTab === 1
                                        ?
                                        <>

                                            {
                                                patients.patients
                                                ?
                                                <>
                                                    <List data={patients.patients} optionSelection={this.setOption} certificate={loginResponse.data && loginResponse.data.building_certification} />
                                                    <section className="pagination-container">
                                                        { this.renderPagination() }
                                                    </section>
                                                </>
                                                : null
                                            }
                                        </>
                                        :
                                        <DailyLogs dailyLogs={this.props.dailyLogs}
                                                   activeLog={ this.props.activeLog }
                                                   tabs={this.props.dailyTabs}
                                                   selectedTab={this.props.selectedTab}
                                                   selectedTabName={this.props.selectedTabName}
                                                   changeTab={this.getLogsPatients}
                                                   updateLog={this.updateLog}
                                                   insertLog={this.insertLog}/>
                                }
                            </ContentCard>
                            <Modal active={medtechSignatureModalActive}>
                                <Signature updateMedtechInfo={this.updateMedtech} pad={medtechPad} setSignature={this.setSignature} type="medtechsave" clearSignature={this.clearSignature} closeModal={this.closeModal} />
                            </Modal>
                        </>
                        : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}><Loader /></div>
                }
            </>
        );
    }
}

//Daily logs functional component
const DailyLogs = ({ tabs, selectedTab, changeTab, insertLog, selectedTabName, dailyLogs, activeLog }) => (
    <>
        {
            dailyLogs && Object.keys(dailyLogs).length > 0
                ?
                <>
                    <div style={{display: "flex", justifyContent: 'space-evenly', width: '100%'}}>
                        {
                            tabs.filter(tab => tab.name !== "").map(tab => (
                                <div style={{display: 'flex'}} key={tab.id}>
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <label style={{ textTransform: 'uppercase' }} className={ selectedTab === tab.id ? "daily-tab-active" : "daily-tab"} onClick={() => changeTab(tab.id, tab.name)}>{tab.name}</label>
                                        {selectedTab === tab.id && (
                                            <div style={{width: 100, backgroundColor: '#006DF0', height: 4}} />
                                        )}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div style={{backgroundColor: '#979797', height: 1, width: '100%'}} />
                    <div style={{display: 'flex', width: '98%'}}>
                        <div style={{display: 'flex', width: '23%', justifyContent:'space-evenly', textAlign:'center'}}>
                            <h5 style={{ color: '#9B9B9B', textTransform: 'capitalize' }}>Resident name</h5>
                        </div>
                        <div style={{display: 'flex', width: '7%', justifyContent:'space-evenly', textAlign:'center'}}>
                            <h5 style={{ color: '#9B9B9B', textTransform: 'capitalize' }}>Room</h5>
                        </div>
                        <div style={{display: 'flex', width: '60%', justifyContent: 'center'}}>
                            <h5 style={{ color: '#9B9B9B', textTransform: 'capitalize' }}>{selectedTabName}</h5>
                        </div>
                        {
                            activeLog.other_name &&
                            <div style={{display: 'flex', width: '10%', justifyContent: 'center'}}>
                                <h5 style={{ color: '#9B9B9B', textTransform: 'capitalize' }}>{ activeLog.other_name }</h5>
                            </div>
                        }
                    </div>
                    <div style={{display: "flex", width: '100%', flexDirection: 'column', alignItems: 'center'}}>
                        {
                            Object.values(dailyLogs).map(dailyLog => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly',
                                    width: '98%',
                                    height: 100,
                                    backgroundColor: '#F7F7F7',
                                    borderRadius: 30,
                                    marginTop: 18
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        width: '23%',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        <div
                                            className="img-d">{dailyLog.patient.profile_picture === null || dailyLog.patient.profile_picture.url === null ?
                                            <img className="avatar-img" src={Avatar} alt="avatar"/> :
                                            <img className="avatar-img" src={dailyLog.patient.profile_picture.url}
                                                 alt="avatar"/>}</div>
                                        <label style={{
                                            fontSize: 24,
                                            color: '#000000'
                                        }}>{`${dailyLog.patient.name} ${dailyLog.patient.lastName.charAt(0)}.`}</label>
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        width: '7%',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        <label style={{
                                            fontSize: 24,
                                            fontWeight: 'bold',
                                            color: '#000000'
                                        }}>{dailyLog.patient.room}</label>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        width: '60%',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        {activeLog.morning && (<div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img alt="morning" src={Morning}/>
                                                <label>Morning</label>
                                            </div>
                                            <input style={{height: 25, width: 25, borderRadius: 10, marginLeft: 10}}
                                                   type="checkbox"
                                                   checked={dailyLog.morning.id !== null && dailyLog.morning.units === "1"}
                                                   onChange={(e) => insertLog(dailyLog.morning, '', e)}/>
                                        </div>)}
                                        {activeLog.noon && (<div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img alt="noon" src={Noon}/>
                                                <label>Noon</label>
                                            </div>
                                            <input style={{height: 25, width: 25, borderRadius: 10, marginLeft: 10}}
                                                   type="checkbox"
                                                   checked={dailyLog.noon.id !== null && dailyLog.noon.units === "1"}
                                                   onChange={(e) => insertLog(dailyLog.noon, '', e)}/>
                                        </div>)}
                                        {activeLog.evening && (<div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img alt="evening" src={Evening}/>
                                                <label>Evening</label>
                                            </div>
                                            <input style={{height: 25, width: 25, borderRadius: 10, marginLeft: 10}}
                                                   type="checkbox"
                                                   checked={dailyLog.evening.id !== null && dailyLog.evening.units === "1"}
                                                   onChange={(e) => insertLog(dailyLog.evening, '', e)}/>
                                        </div>)}
                                        {activeLog.bedtime && (<div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <img alt="bedtime" src={Bedtime}/>
                                                <label>Bedtime</label>
                                            </div>
                                            <input style={{height: 25, width: 25, borderRadius: 10, marginLeft: 10}}
                                                   type="checkbox"
                                                   checked={dailyLog.bedtime.id !== null && dailyLog.bedtime.units === "1"}
                                                   onChange={(e) => insertLog(dailyLog.bedtime, '', e)}/>
                                        </div>)}
                                    </div>
                                    {activeLog.other && (<div style={{
                                        display: 'flex',
                                        width: '10%',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}>
                                                <input maxLength="3" type="number"
                                                       value={dailyLog[activeLog.other_name].units === null ? '' : dailyLog[activeLog.other_name].units}
                                                       style={{
                                                           width: 79,
                                                           borderRadius: 10,
                                                           borderWidth: 1,
                                                           borderColor: '#CECECE',
                                                           textAlign: 'center'
                                                       }}
                                                       onChange={(e) => insertLog(dailyLog[activeLog.other_name], e.target.value, e)}/>
                                                <label>{activeLog.other_unit}</label>
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            ))
                        }
                    </div>
                </>
                :
                null
        }
    </>
);

const mapStateToProps = state => ({
    patients: state.patients.items,
    loginResponse: state.login.loginResponse,
    domain: state.domain.domain,
    settings: state.settings,
    dailyTabs: state.logs.tabs,
    mainTab: state.logs.mainTab,
    selectedTab: state.logs.selectedTab,
    selectedTabName: state.logs.selectedTabName,
    updateSuccess: state.logs.updateSuccess,
    loggedIn: state.login.loggedIn,
    search: state.logs.search,
    updatingSuccess: state.login.updatingSuccess,
    medtechSignatureModalActive: state.modal.medtechSignatureModalActive,
    medtechSignature: state.patientSession.medtechSignature,
    dailyLogs: state.logs.dailyLogs,
    activeLog: state.logs.activeLog
});

export default connect(
    mapStateToProps,
    {
        getResidencePatients,
        changeSearch,
        setPatientMedsType,
        setPatientInfo,
        logOut,
        activateLoginAlert,
        clearDomain,
        dailyLogsTabs,
        getDailyLogsPatients,
        changeTab,
        changeMainTab,
        createDailyLog,
        updateDailyLog,
        updateDailyLogUnits,
        deleteDailyLog,
        getMedtechData,
        setDomain,
        setDomainFlag,
        setMedtechInfo,
        getSettings,
        setPadStyle,
        setOtherPadStyle,
        activatePatientSignature,
        activateMedtechSignature,
        setMedtechSignature,
        clearMedTechSignature,
        updateMedtechInfo,
        clearPatientSignature,
        setAuth
    }
)(ResidentSearch);