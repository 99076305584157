import React from 'react';
import {Route, HashRouter as Router, Switch } from 'react-router-dom';
import Login from '../components/Login/Login';
import ResidentSearch from '../components/ResidentSearch/ResidentSearch';
import ConfirmResident from '../components/ConfirmResident/ConfirmResident';
import Schedule from '../components/Schedule/Schedule';
import MedicationSelection from '../components/MedicationSelection/MedicationSelection';
import MedicationSigning from '../components/MedicationSigning/MedicationSigning';
import ScheduleConfirmation from '../components/ScheduleConfirmation/ScheduleConfirmation';
import Refills from '../components/Refills/Refills';

const routes = ({ loggedIn, domainSet }) => (
  <Router>
    <Switch>
      <Route path="/" component={ loggedIn && domainSet ? ResidentSearch : Login } exact />
      <Route path="/login" component={ Login } exact />
      <Route path="/patients" component={ ResidentSearch } exact  />
      <Route path="/confirm" component={ loggedIn && domainSet ? ConfirmResident : ResidentSearch } exact />
      <Route path="/schedule" component={ loggedIn && domainSet ? Schedule : ResidentSearch } exact />
      <Route path="/medication_selection" component={ loggedIn && domainSet ? MedicationSelection : ResidentSearch } exact />
      <Route path="/medication_signing" component={ loggedIn && domainSet ? MedicationSigning : ResidentSearch } exact />
      <Route path="/medication_schedule_confirmation" component={ loggedIn && domainSet ? ScheduleConfirmation : ResidentSearch } exact />
      <Route path="/refills" component={ loggedIn && domainSet ? Refills : ResidentSearch } exact />
    </Switch>
  </Router>
);

export default routes;