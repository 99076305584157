import { GET_PATIENTS, SET_SEARCH } from '../actions/types';

const initialState = {
  items: [],
  search: "",
};

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_PATIENTS:
      return {
        ...state,
        items: action.payload
      }
    case SET_SEARCH:
      return {
        ...state,
        search: action.payload
      } 
    default:
      return state;
  }
}