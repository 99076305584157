import routes from './constants';
import { createInstance } from './utils';

const getPatientMeds = async(URL, authHeaders, patientId, type) => {
  const getPatientMedsRoute = type === 'narcotic' ? routes['PATIENT_NARCOTICS'].replace('{patientId}', patientId) : routes['PATIENT_MEDS'].replace('{patientId}', patientId);
  const instance = createInstance(URL, authHeaders);
  return await instance.get(getPatientMedsRoute);
};

const getPatientRefills = async(URL, authHeaders, patientId) => {
  const getPatientMedsRoute = routes['PATIENT_REFILLS'].replace('{patientId}', patientId);
  const instance = createInstance(URL, authHeaders);
  return await instance.get(getPatientMedsRoute);
};

const signPatientRefill = async(URL, authHeaders, refillId, patientSignature, medtechSignature) => {
  const signPatientRefillRoute = routes['SIGN_REFILL'].replace('{refillId}', refillId);
  const data = {
    "patient_signature": patientSignature,
    "signature": medtechSignature,
  };
  const instance = createInstance(URL, authHeaders);
  return await instance.put(signPatientRefillRoute, data );
};

const signPatientMeds = async(URL, authHeaders, meds, status, patientSignature, medtechSignature, type, isAmend = false, certificate, comment) => {
  const signPatientMedsRoute = routes['SIGN_MEDS'];
  const signPatientAmendMedsRoute = routes['SIGN_MEDS_AMEND'];

  const data =
    certificate === 'default'
    ?
      type === 'narcotic' 
      ?
      {
        meds,
        "status_signature": patientSignature,
        "taken_pills_signature": patientSignature,
        "current_status": status,
        "medtech_signature": medtechSignature
      }
      :
      {
        meds,
        "status_signature": patientSignature,
        "taken_pills_signature": patientSignature,
        "current_status": status,
      }
    :
      type === 'narcotic' 
      ?
      {
        meds,
        "status_signature": medtechSignature,
        "taken_pills_signature": medtechSignature,
        "current_status": status,
        "medtech_signature": medtechSignature
      }
      :
      {
        meds,
        "medtech_signature": medtechSignature,
        "taken_pills_signature": medtechSignature,
        "current_status": status,
      };

  const instance = createInstance(URL, authHeaders);
  return await instance.post(isAmend ? signPatientAmendMedsRoute : signPatientMedsRoute, data);
};

const updateNarcoticAvailableAmount = async (URL, authHeaders, medId, amount) => {
  const toInt = parseInt(amount);
  const instance = createInstance(URL, authHeaders);
  const updateNarcoticAvailableAmountRoute = routes['UPDATE_AVAILABLE_NARCOTIC'].replace('{medId}', medId);
  const data = {
    "med": {
      "available": toInt
    }
  };
  return await instance.put(updateNarcoticAvailableAmountRoute, data);
};

const diagnosePatient = async (URL, authHeaders, diagnoses) => {
  const instance = createInstance(URL, authHeaders);
  const body = {
    "vital_signs": [
      {
        "name": "temperature",
        "value": diagnoses.temp,
      },
      {
        "name": "blood_pressure",
        "value": diagnoses.blood_pressure,
      },
      {
        "name": "heart_rate",
        "value": diagnoses.heart_rate,
      },
      {
        "name": "oxygen_saturation",
        "value": diagnoses.oxygen_saturation,
      },
      {
        "name": "weight",
        "value": diagnoses.mass,
      },
      {
        "name": "respiratory_rate",
        "value": diagnoses.respiratory_rate,
      }
    ],
    "diagnoses": [
      {
        "headache": diagnoses.headache_state,
        "fever": diagnoses.fever_state,
        "cough": diagnoses.cough_state,
      }
    ],
    "alarm_signs": [
      {
        "breathing_difficulty": diagnoses.breathing_difficulty,
        "low_breathing_rate": diagnoses.low_breathing_rate,
        "low_systolic_blood_pressure": diagnoses.low_systolic_blood_pressure,
        "low_coma_glashow_scale": diagnoses.low_coma_glashow_scale,
        "exacerbation_of_blood_glucose_control": diagnoses.exacerbation_of_blood_glucose_control,
        "pulse_oximeter_availability": diagnoses.pulse_oximeter_availability,
        "low_oxygen_saturation": diagnoses.low_oxygen_saturation,
      }
    ]
  };
  return await instance.post(routes['DIAGNOSES'], body);
};

export { 
  getPatientMeds,
  getPatientRefills,
  signPatientRefill,
  signPatientMeds,
  updateNarcoticAvailableAmount,
  diagnosePatient,
};