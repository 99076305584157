/*Component not being used due to the unification of the UI with dashboard*/
import React, {Component} from 'react';
import Input from '../../shared/components/Input/Input';
import Button from '../../shared/components/Button/Button';
import CopyRight from "../../shared/components/CopyRight/CopyRight";
import './loginStyles.css';
import '../Workspace/workSpaceStyles.css'
import Logo from '../../shared/images/logo.png';
import { connect } from 'react-redux';
import { login, setDomain, setUser, setPassword, activateLoginAlert } from '../../actions/loginActions';
import { setDomainFlag } from '../../actions/domainActions';
import { setMedtechInfo } from '../../actions/patientSessionActions';
import { getSettings } from '../../actions/settingsActions';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;

library.add(faExclamationCircle);

class Login extends Component {
  setDomain = (e) => {
    this.props.setDomain(e.target.value);
  };

  setUser = (e) => {
    this.props.setUser(e.target.value);
  };
  
  setPassword = (e) => {
    this.props.setPassword(e.target.value);
  };
  
  submit = (e) => {
    e.preventDefault();
    const { domain, user, password } = this.props;
    this.props.login(`https://${domain}.${DOMAIN_EXTENSION}`, user, password);
  };

  componentDidUpdate(){
    const { domain, loggedIn, loginResponse } = this.props;
    const { headers } = loginResponse;

    if(loggedIn === 'LOGIN_SUCCESS') {
        this.props.setDomainFlag();
        this.props.setMedtechInfo(loginResponse);
        this.props.getSettings(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
        this.props.activateLoginAlert(false);
        this.props.history.push('/patients');
    } else if(loggedIn === 'LOGIN_FAILURE') {
        this.props.activateLoginAlert(true);
    }
  }

  render() {
    const { active } = this.props;
    return(
      <div className="flex-container">
          <div className="left">
            <div id="header">
              <img src={Logo} alt="logo" className="logo" />
            </div>
            <form onSubmit={this.submit} id="body" className="login-form" method="post">
              <div className="ws-title-container">
                <b className="l-title">Sign in</b>
              </div>
              <div className="ws-input-container">
                <p className="l-sub-text">Please, enter your domain, user and password to enter the web app</p>
                <Input placeholder="domain" type="ws-input" name="domain" value={this.props.domain} handle={this.setDomain} inputType="text" />
                <Input placeholder="user" type="ws-input" name="user" value={this.props.user} handle={this.setUser} inputType="text"  />
                <Input placeholder="password" type="ws-input" name="password" value={this.props.password} handle={this.setPassword} inputType="password" />
              </div>
              {active ? <div className="alert-container"><FontAwesomeIcon icon="exclamation-circle" /><b className="login-alert">Invalid e-mail or password</b></div> : null}
              <div className="ws-sub-text-container">
                <a className="link" href="/login">Forgot your password?</a>
                <p className="l-sub-text">We'll help you reset it, and get back on track.</p>
              </div>
              <div className="ws-btn-container">
                <Button type="continue" text="Enter" buttonType="submit" />
              </div>
            </form>
          </div>
          <div className="right">
            <CopyRight />
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.login.user,
  password: state.login.password,
  loginResponse: state.login.loginResponse,
  domain: state.domain.domain,
  loggedIn: state.login.loggedIn,
  active: state.login.alert,
});

export default connect(mapStateToProps, { login, setDomain, setDomainFlag, setUser, setPassword, setMedtechInfo, activateLoginAlert, getSettings })(Login);