import { 
  ACTIVATE_MODAL, 
  ACTIVATE_OTHER_MODAL, 
  DEACTIVATE_MODAL, 
  SET_MED_TO_SKIP, 
  SET_AS_NEEDED_MED, 
  DEACTIVATE_OTHER_MODAL, 
  SET_REASON_TO_SKIP, 
  SET_AS_NEEDED_REASON_TO_SKIP, 
  CLEAR_REASONS, 
  SET_LOCK_SCREEN_PASSWORD, 
  CLEAR_LOCK_SCREEN_PASSWORD, 
  ACTIVATE_LOCK_SCREEN, 
  SET_PAD_STYLE, 
  SET_AS_NEEDED_REASON_REMINDER_TIME, 
  CLEAR_AS_NEEDED_REASONS_MODAL_DATA,
  SET_OTHER_PAD_STYLE,
  ACTIVATE_PATIENT_SIGNATURE,
  ACTIVATE_MEDTECH_SIGNATURE,
  OPTIONS_MODAL,
} from './types';

const activateModal = (option) => dispatch => {
    dispatch({
        type: ACTIVATE_MODAL,
        payload: option,
    })
}

const setReasonToSkip = (reason) => dispatch => {
  dispatch({
    type: SET_REASON_TO_SKIP,
    payload: reason,
  })
}

const handleOptionsModal = (option) => dispatch => {
  dispatch({
    type: OPTIONS_MODAL,
    payload: option
  })
}

const setAsNeededReason = (reason) => dispatch => {
  dispatch({
    type: SET_AS_NEEDED_REASON_TO_SKIP,
    payload: reason,
  })
}

const setAsNeededReminderTime = (time) => dispatch => {
  dispatch({
    type: SET_AS_NEEDED_REASON_REMINDER_TIME,
    payload: time,
  })
}

const clearReasons = () => dispatch => {
  dispatch({
    type: CLEAR_REASONS,
  })
}

const activateOtherModal = (option) => dispatch => {
  dispatch({
      type: ACTIVATE_OTHER_MODAL,
      payload: option,
  })
}

const deactivateModal = (option) => dispatch => {
    dispatch({
        type: DEACTIVATE_MODAL,
        payload: option,
    })
}

const deactivateOtherModal = (option) => dispatch => {
  dispatch({
      type: DEACTIVATE_OTHER_MODAL,
      payload: option,
  })
}

const setMedToSkip = (med) => dispatch => {
  dispatch({
    type: SET_MED_TO_SKIP,
    payload: med
  })
}

const setAsNeededMed = (med) => dispatch => {
  dispatch({
    type: SET_AS_NEEDED_MED,
    payload: med
  })
}

const setLockScreenPassword = (password) => dispatch => {
  dispatch({
    type: SET_LOCK_SCREEN_PASSWORD,
    payload: password
  })
}

const clearLockScreenPassword = () => dispatch => {
  dispatch({
    type: CLEAR_LOCK_SCREEN_PASSWORD,
  })
}

const activateLockScreen = (bool) => dispatch => {
  dispatch({
    type: ACTIVATE_LOCK_SCREEN,
    payload: bool
  })
}

const setPadStyle = (style) => dispatch => {
  dispatch({
    type: SET_PAD_STYLE,
    payload: style
  })
}

const setOtherPadStyle = (style) => dispatch => {
  dispatch({
    type: SET_OTHER_PAD_STYLE,
    payload: style
  })
}

const clearAsNeededModalData = () => dispatch => {
  dispatch({
    type: CLEAR_AS_NEEDED_REASONS_MODAL_DATA,
  })
}

const activatePatientSignature = (bool) => dispatch => {
  dispatch({
    type: ACTIVATE_PATIENT_SIGNATURE,
    payload: bool,
  })
}

const activateMedtechSignature = (bool) => dispatch => {
  dispatch({
    type: ACTIVATE_MEDTECH_SIGNATURE,
    payload: bool,
  })
}

export {
  activateModal,
  deactivateModal,
  setMedToSkip,
  setAsNeededMed,
  activateOtherModal,
  deactivateOtherModal,
  setReasonToSkip,
  setAsNeededReason,
  clearReasons,
  setLockScreenPassword,
  clearLockScreenPassword,
  activateLockScreen,
  setPadStyle,
  setAsNeededReminderTime,
  clearAsNeededModalData,
  setOtherPadStyle,
  activatePatientSignature,
  activateMedtechSignature,
  handleOptionsModal,
}