import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faRedoAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './signatureStyles.css';
import { updateMedtechInfo } from '../../../actions/loginActions';

library.add(faCheck, faRedoAlt, faWindowClose);

const clearPad = (pad, clearSignature, type) => {
  pad.clear();
  clearSignature(type);
}

const saveSignature = (setSignature, type, pad) => {
  const signature = pad.getTrimmedCanvas().toDataURL('image/png');
  setSignature(signature, type)
}

const Signature = ({ pad, setSignature, type, clearSignature, closeModal, signature }) => {
  return(
    <div style={{width: '100%', display: 'flex'}}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} onClick={() => saveSignature(setSignature, type, pad)} >
        <SignatureCanvas 
          clearOnResize={false}
          penColor='black'
          canvasProps = {
            { 
              className: 'full-screen',
              height: window.innerHeight,
              width: window.innerWidth,
            } 
          }
          ref={(ref) => { pad = ref }}  
        >
        </SignatureCanvas>
        <div style={{display: 'flex', position: 'absolute', width:'100%', top: '0%'}}>
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: '#F6F6F6', padding: 20, borderRadius: 10}}>
            <div style={{display: 'flex', justifyContent: 'center'}}><label style={{fontSize: 28}}>Please</label><label style={{fontWeight: 'bold', marginLeft: 5, fontSize: 28}}>Sign</label></div>
            <p style={{fontSize: 18}}>Looks like you don't have a saved signature, please sign below and continue when ready</p>
          </div>
        </div>
        <div style={{display: 'flex', position: 'absolute', width: '100%', bottom: '10%'}}>
          <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
            <button onClick={() => clearPad(pad, clearSignature, type)} className="repeat"><FontAwesomeIcon icon="redo-alt" color="#4A90E2" size="2x" />Repeat</button>
            <button onClick={type === 'medtechsave' ? () => updateMedtechInfo() : () => closeModal(type)} className="s-continue"><FontAwesomeIcon icon="check" color="#FFFFFF" size="2x" />Save & Continue</button>
          </div>
        </div>
      </div>
      {/*<div style={{display: 'flex', zIndex: 100, width:'100%'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', backgroundColor: '#F6F6F6', padding: 20, borderRadius: 10}}>
          <div style={{display: 'flex', justifyContent: 'center'}}><label style={{fontSize: 28}}>Please</label><label style={{fontWeight: 'bold', marginLeft: 5, fontSize: 28}}>Sign</label></div>
          <p style={{fontSize: 18}}>Looks like you don't have a saved signature, please sign below and continue when ready</p>
        </div>
      </div>
      <div style={{display: 'flex', zIndex: 100, width: '100%'}}>
        <div style={{display: 'flex', justifyContent: 'space-evenly', width: '30%'}}>
          <button onClick={() => clearPad(pad, clearSignature, type)} className="repeat"><FontAwesomeIcon icon="redo-alt" color="#4A90E2" size="2x" />Repeat</button>
          <button onClick={type === 'medtechsave' ? () => updateMedtechInfo() : () => closeModal(type)} className="s-continue"><FontAwesomeIcon icon="check" color="#FFFFFF" size="2x" />Save & Continue</button>
        </div>
      </div>*/}
    </div>
  );
}

export default Signature;
