import { 
    SET_TEMPERATURE, 
    SET_BLOOD_PRESSURE,  
    SET_HEART_RATE,
    SET_WEIGHT,
    SET_RESPIRATORY_RATE,
    SET_OXYGEN_SATURATION,
    SET_HEADACHE,
    SET_FEVER,
    SET_COUGH,
    SET_BREATHING_DIFFICULTY,
    SET_LOW_BREATHING_RATE,
    SET_LOW_SYSTOLIC_BLOOD_PRESSURE,
    SET_LOW_COMA_GLASHOW_SCALE,
    SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL,
    SET_PULSE_OXIMETER_AVAILABILITY,
    SET_LOW_OXYGEN_SATURATION,
    DIAGNOSE_PATIENT_SUCCESS,
    DIAGNOSE_PATIENT_FAILURE,
    RESET_DIAGNOSES,
} from './types';

import { diagnosePatient } from '../api/meds';

const temperature = (value) => dispatch => {
  dispatch({
      type: SET_TEMPERATURE,
      payload: value,
  })
};

const blood = (value) => dispatch => {
    dispatch({
        type: SET_BLOOD_PRESSURE,
        payload: value,
    })
  };

  const oxygen = (value) => dispatch => {
    dispatch({
        type: SET_OXYGEN_SATURATION,
        payload: value,
    })
  };

  const heart = (value) => dispatch => {
    dispatch({
        type: SET_HEART_RATE,
        payload: value,
    })
  };

  const weight = (value) => dispatch => {
    dispatch({
        type: SET_WEIGHT,
        payload: value,
    })
  };

  const respiratory = (value) => dispatch => {
    dispatch({
        type: SET_RESPIRATORY_RATE,
        payload: value,
    })
  };

  const headache = (bool) => dispatch => {
    dispatch({
        type: SET_HEADACHE,
        payload: bool,
    })
  };

  const fever = (bool) => dispatch => {
    dispatch({
        type: SET_FEVER,
        payload: bool,
    })
  };

  const cough = (bool) => dispatch => {
    dispatch({
        type: SET_COUGH,
        payload: bool,
    })
  };

  const breathingDifficulty = (bool) => dispatch => {
    dispatch({
        type: SET_BREATHING_DIFFICULTY,
        payload: bool,
    })
  };

  const lowBreathingRate = (bool) => dispatch => {
    dispatch({
        type: SET_LOW_BREATHING_RATE,
        payload: bool,
    })
  };

  const lowSystolicBloodPressure = (bool) => dispatch => {
    dispatch({
        type: SET_LOW_SYSTOLIC_BLOOD_PRESSURE,
        payload: bool,
    })
  };

  const lowComa = (bool) => dispatch => {
    dispatch({
        type: SET_LOW_COMA_GLASHOW_SCALE,
        payload: bool,
    })
  };

  const exacerbation = (bool) => dispatch => {
    dispatch({
        type: SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL,
        payload: bool,
    })
  };

  const oximeter = (bool) => dispatch => {
    dispatch({
        type: SET_PULSE_OXIMETER_AVAILABILITY,
        payload: bool,
    })
  };

  const lowOxygenSaturation = (bool) => dispatch => {
    dispatch({
        type: SET_LOW_OXYGEN_SATURATION,
        payload: bool,
    })
  };

  const sendPatientDiagnoses = (URL, authHeaders, diagnoses) => dispatch => {
    diagnosePatient(URL, authHeaders, diagnoses)
    .then(response => {
      dispatch({
        type: DIAGNOSE_PATIENT_SUCCESS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: DIAGNOSE_PATIENT_FAILURE,
        payload: error 
      });
    })
  };

  const resetDiagnoses = () => dispatch => {
    dispatch({
      type: RESET_DIAGNOSES,
    })
  }
export { 
    temperature,
    heart,
    blood,
    weight,
    respiratory,
    oxygen,
    headache,
    fever,
    cough,
    breathingDifficulty,
    lowBreathingRate,
    lowSystolicBloodPressure,
    lowComa,
    exacerbation,
    oximeter,
    lowOxygenSaturation,
    sendPatientDiagnoses,
    resetDiagnoses,
};
