import React from "react";
import logoWhite from '../../images/logo-Arum-Health-White.png'
import "./copyRightStyles.css";
import {Link} from "react-router-dom";

const CopyRight = () => (
  <div id="copy-right">
    <div id="copy-right-container">
      <div id="copy-right-text">
        <p>
          © Arum Health
        </p>
        <p>
          All Rights Reserved 2016 - 2019
        </p>
      </div>
      <img id="copy-right-logo" src={logoWhite} alt="logo" />
    </div>
    <div id="terms-container">
      <Link to="/login">
        Terms of Use and Privacy Policy
      </Link>
    </div>
  </div>
);

export default CopyRight;
