/*
This component is now utilized as the main component for medication take 
after login was eliminated and integrated with the dashboard.
This is where the the schedule selection, medication selection and signature 
components are called as child components.
The vital signs component is also called upon when needed here as a child component.
*/

import React, {Component} from 'react';
import Header from '../../shared/components/Header/Header';
import ContentCard from '../../shared/components/ContentCard/ContentCard';
import Buttons from '../../shared/components/Buttons/Buttons';
import StatusBar from '../../shared/components/StatusBar/StatusBar';
import Schedule from '../Schedule/Schedule';
import MedicationSelection from '../MedicationSelection/MedicationSelection';
import Button from '../../shared/components/Button/Button';
import Covid from '../../shared/images/ico-covid-white-1x.png';
import Signature from '../../shared/components/Signature/Signature';
import { getDate } from '../../shared/utils';
import { fullScreen } from '../utils';
import { clearComplete } from '../../actions/medsActions';
import './confirmResidentStyles.css';
import { connect } from 'react-redux';
import Modal from '../../shared/components/Modal/Modal';
import { setEmoji, clearEmoji } from '../../actions/emojiActions';
import { 
  setStatus, 
  clearPatientSession, 
  deleteNarcoticFromList, 
  setPatientMedsType, 
  clearPartialSession,
  restorePatientFormattedMed,
  clearPatientSignature,
  clearMedTechSignature,
  restorePatientMed,
  setPatientFormattedMed,
  setPatientMed,
  setPatientSignature,
  setMedtechSignature,
} from '../../actions/patientSessionActions';
import { logOut } from '../../actions/loginActions';
import { 
  temperature,
  blood, heart,
  oxygen,
  weight,
  respiratory,
  headache,
  fever,
  cough,
  breathingDifficulty,
  lowBreathingRate,
  lowSystolicBloodPressure,
  lowComa,
  exacerbation,
  oximeter,
  lowOxygenSaturation,
  sendPatientDiagnoses,
  resetDiagnoses,
} from '../../actions/covidActions';
import { clearDomain } from '../../actions/domainActions';
import { 
  activateModal,
  deactivateModal,
  activateOtherModal,
  deactivateOtherModal,
  setPadStyle,
  setOtherPadStyle,
  setReasonToSkip,
  clearReasons,
  activatePatientSignature,
  activateMedtechSignature,
  setAsNeededMed, 
  setAsNeededReason, 
  setAsNeededReminderTime,
  clearAsNeededModalData,
  handleOptionsModal,
} from '../../actions/modalActions';
import { 
  refillSigning,
  medSigning,
  clearMedEvent
} from '../../actions/medeventActions';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationTriangle, faBatteryThreeQuarters } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../../shared/components/Input/Input';
import SweetAlert from 'sweetalert2-react';
import Pen from '../../shared/images/pen.png';

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;

let patientPad = {}
let medtechPad = {}

library.add(faExclamationTriangle, faBatteryThreeQuarters);

class ConfirmResident extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
  };

  setPatientStatus = (emoji) => {
    this.props.setEmoji(emoji)
    this.props.setStatus(emoji)
  };
  //sends vital signs event
  sendDiagnoses = () => {
    const { 
      domain, 
      loginResponse,
      temp,
      blood_pressure,
      heart_rate,
      oxygen_saturation,
      mass,
      respiratory_rate,
      headache_state,
      fever_state,
      cough_state,
      breathing_difficulty,
      low_breathing_rate,
      low_systolic_blood_pressure,
      low_coma_glashow_scale,
      exacerbation_of_blood_glucose_control,
      pulse_oximeter_availability,
      low_oxygen_saturation,
    } = this.props;
    const { headers } = loginResponse;
    const diagnoses = {
      temp,
      blood_pressure,
      heart_rate,
      oxygen_saturation,
      mass,
      respiratory_rate,
      headache_state,
      fever_state,
      cough_state,
      breathing_difficulty,
      low_breathing_rate,
      low_systolic_blood_pressure,
      low_coma_glashow_scale,
      exacerbation_of_blood_glucose_control,
      pulse_oximeter_availability,
      low_oxygen_saturation,
    };
    this.props.sendPatientDiagnoses(`https://${domain}.${DOMAIN_EXTENSION}`, headers, diagnoses)
  };
  //main component back function
  back = () => {
    this.props.clearPatientSession();
    this.props.clearEmoji();
    this.props.history.push('/patients')
    this.reset();
  };
  //main component continue function
  //wraps vital signs and med event function call
  continue = () => {
    const { medicationType } = this.props;
    medicationType === 'covid'
    ?
      this.sendDiagnoses()
    :
      this.sendMedEvent();
  };
  //handles all input onchange for vital signs according to target name
  inputHandler = (e) => {
    this.props[e.target.name](e.target.value);
    console.log(e.target.value)
  };
  //handles checkbox onchange for vital signs
  checkBoxHandler = (e) => {
    this.props[e.target.id](e.target.checked);
    console.log(e.target.checked)
  };
  //resets vital signs input values after each event
  reset = () => {
    this.props.resetDiagnoses()
    this.props.history.push('/patients')
  }
  
  activatePatientSignatureModal = () => {
    this.props.activatePatientSignature(true);
    fullScreen();
  }

  activateMedtechSignatureModal = () => {
    this.props.activateMedtechSignature(true);
    fullScreen();
  }
  //closes signature modals by type(patient, med tech)
  closeModal = (type) => {
    type === 'patient' 
      ? this.props.activatePatientSignature(false)
      : this.props.activateMedtechSignature(false);
  }
  //renders the options menu after every event
  renderOptions = () => {
    const { patient, medTech } = this.props;
    const { data: medTechData } = medTech
    const { building_certification } = medTechData

      if(building_certification === 'default') {
      if(patient.takes_narcotic && patient.pending_refills) {
        return (
          <div className="options-container">
            <Button type="option-sml" onClick={() => this.setOption("regular")}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
              <label>narcotic</label>
            </Button>
            <Button type="option-refill-sml" onClick={() => this.setOption("refill")}>
              <FontAwesomeIcon icon="battery-three-quarters" />
              <label>refill</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
              {  patient.covid_19
                  ?
                    <img src={Covid} alt="icon" />
                  : 
                    <>
                      <FontAwesomeIcon icon="heartbeat" />
                      <label>vitals</label>
                    </>
              }
            </Button>
          </div>
        )
      } else if(patient.takes_narcotic && patient.pending_refills===false) {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
                <label>select</label>
              </Button>
              <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
                <FontAwesomeIcon icon="prescription-bottle-alt" />
                <label>narcotic</label>
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : 
                      <>
                        <FontAwesomeIcon icon="heartbeat" />
                        <label>vitals</label>
                      </>
                }
              </Button>
            </div>
          )
      } else if(patient.pending_refills) {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
                <label>select</label>
              </Button>
              <Button type="option-refill-sml" onClick={() => this.setOption("refill")}>
                <FontAwesomeIcon icon="battery-three-quarters" />
                <label>refill</label>
              </Button>
            </div>
          )
      } else {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
                <label>select</label>
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : 
                      <>
                        <FontAwesomeIcon icon="heartbeat" />
                        <label>vitals</label>
                      </>
                }
              </Button>
            </div>
          )
      }
    } else {
      if(patient.takes_narcotic) {
        return (
          <div className="options-container">
            <Button type="option-sml" onClick={() => this.setOption("regular")}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
              <label>narcotic</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
              {  patient.covid_19
                  ?
                    <img src={Covid} alt="icon" />
                  : 
                    <>
                      <FontAwesomeIcon icon="heartbeat" />
                      <label>vitals</label>
                    </>
              }
            </Button>
          </div>
        )
      } else {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
                <label>select</label>
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "option-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                      
                    : 
                      <>
                        <FontAwesomeIcon icon="heartbeat" />
                        <label>vitals</label>
                      </>
                }
              </Button>
            </div>
          )
      }
    }
  };
  //redirects according to selected option in options menu
  setOption = (type) => {
    const { status } = this.props;
    this.props.clearPartialSession();
    this.props.setPatientMedsType(type);
    this.props.handleOptionsModal(false);
    type === 'refill' 
      ? this.props.history.push('/refills') 
      : type === 'covid' 
        ? this.props.history.push('/confirm') 
        : status.length > 0 
          ? this.props.history.push('/confirm') 
          : this.props.history.push('/confirm') ;
  }

  sendMedEvent = () => {
    const { medicationType, refillToSign, patientSignature, medtechSignature, status, meds, domain, loginResponse, isAmend, medTech, clearComplete } = this.props;
    const { comment } = this.state;
    const { data: medTechData } = medTech;
    const { building_certification } = medTechData;
    const { headers } = loginResponse;
    const { id } = refillToSign;
    const medTechSignature = building_certification !== 'default' ? medTechData.latest_signature : medtechSignature;
    clearComplete();
    medicationType === 'refill' 
      ? this.props.refillSigning(`https://${domain}.${DOMAIN_EXTENSION}`, headers, id, patientSignature, medtechSignature)
      : this.props.medSigning(`https://${domain}.${DOMAIN_EXTENSION}`, headers, meds, status, patientSignature,  medTechSignature, medicationType, isAmend, building_certification, comment);
  }

  setReason = (e) => {
    this.props.setReasonToSkip(e.target.value);
  }

  setAsNeededReason = (e) => {
    this.props.setAsNeededReason(e.target.value);
  }

  setAsNeededReasonReminderTime = (e) => {
    this.props.setAsNeededReminderTime(e.target.value);
  }

  asNeededModalBack = () => {
    this.props.clearAsNeededModalData();
    this.props.deactivateOtherModal(false);
  }

  asNeededModalContinue = () => {
    const { formattedSelectedMedication, asNeeded, selectedMedication } = this.props;
    const medication = this.formatMed(asNeeded, "takePill");
    this.props.restorePatientFormattedMed(asNeeded, formattedSelectedMedication);
    this.props.restorePatientMed(asNeeded, selectedMedication);
    this.props.setPatientFormattedMed(medication);
    this.props.setPatientMed(asNeeded);
    this.props.clearAsNeededModalData();
    this.props.deactivateOtherModal(false);
  }

  modalBack = () => {
    this.props.deactivateModal(false);
  }

  modalContinue = () => {
    const { formattedSelectedMedication, skippedMed, selectedMedication } = this.props;
    const medication = this.formatMed(skippedMed, "refusedPill");
    this.props.restorePatientFormattedMed(skippedMed, formattedSelectedMedication);
    this.props.restorePatientMed(skippedMed, selectedMedication);
    this.props.setPatientFormattedMed(medication);
    this.props.setPatientMed(skippedMed);
    this.props.deactivateModal(false);
    this.props.clearReasons()
  }
  //formats selected medication according to med event needs
  formatMed = (med, type) => {
    const { reasons, schedule, asNeededReason, asNeededReasonReminderTime} = this.props;
    let formattedMed;
    schedule === 'as_needed'
    ?
    formattedMed = {
      "patient_id": med.patient_id,
      "med_id": med.id,
      "event_name": type,
      "event_date": getDate() ,
      "postponed_until": '',
      "symtoms": "",
      "notes": reasons,
      "reason": asNeededReason,
      "reminder": asNeededReasonReminderTime,
      "schedule": med.schedule,
    }
    : 
    formattedMed = {
      "patient_id": med.patient_id,
      "med_id": med.id,
      "event_name": type,
      "event_date": getDate() ,
      "postponed_until": '',
      "symtoms": "",
      "notes": reasons,
      "schedule": med.schedule,
    }
    return formattedMed
  }
  //redirects to patient list and cleans state
  exit = () => {
    this.props.clearPatientSession();
    this.props.clearEmoji();
    this.props.handleOptionsModal(false);
    this.props.history.push('/patients');
    this.reset();
  }
  //sets signature according to type
  setSignature = (signature, type) => {
    console.log('setting ', type, 'signature')
    type === 'patient' ? this.props.setPatientSignature(signature): this.props.setMedtechSignature(signature);
  }

  clearSignature = (type) => {
    type === 'patient'
    ? this.props.clearPatientSignature()
    : this.props.clearMedTechSignature();
  }
  
  async componentDidUpdate() {
    const { med_event_success, medicationType, narcoticToSign, medsList, saved_successful } = this.props;
    if(med_event_success === true && medicationType === 'refill') {
      await this.renderPatients();
      this.props.clearMedEvent(false);
      this.props.clearPatientSignature()
      this.props.history.push('/refills');
    } else if(med_event_success === true && medicationType === 'narcotic') {
      this.props.deleteNarcoticFromList(narcoticToSign, medsList)
      this.props.clearMedEvent(false);
      this.props.handleOptionsModal(true);
    } else if(med_event_success === true) {
      this.props.clearMedEvent(false);
      this.props.handleOptionsModal(true);
    } else if(saved_successful) {
      this.reset();
    }
  }

  goTo = () => {
    this.props.history.push('/medication_signing');
  }

  render(){
    const {
      loginResponse,
      medTech, 
      patient, 
      medicationType, 
      status, 
      logOut, 
      clearDomain, 
      settings,
      temp,
      blood_pressure,
      heart_rate,
      oxygen_saturation,
      mass,
      respiratory_rate,
      headache_state,
      fever_state,
      cough_state,
      breathing_difficulty,
      low_breathing_rate,
      low_systolic_blood_pressure,
      low_coma_glashow_scale,
      exacerbation_of_blood_glucose_control,
      pulse_oximeter_availability,
      low_oxygen_saturation,
      saved_successful,
      medtechSignature,
      patientSignature,
      asNeededReason,
      asNeededReasonReminderTime,
      asNeeded,
      otherActive,
      skippedMed,
      active,
      meds,
      optionsActive,
      medtechSignatureModalActive,
      patientSignatureModalActive,
    } = this.props;
    const { data: medTechData } = medTech
    return(
      <>
        <Header med_tech={medTechData} logOut={logOut} clearDomain={clearDomain} settings={settings} />
        <ContentCard>
          <StatusBar patient={patient} setPatientStatus={this.setPatientStatus} status={status} />
          {  
            medicationType === 'covid'
            ?
              <>
                <section className="container mb-4">
                  <h2 className="mb-4">Vital Signs</h2>
                  <div className="row mb-5">
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Blood pressure(mmHg)</label>
                        <Input placeholder="..." type="form-control" name="blood" value={blood_pressure} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Heart rate(BPM)</label>
                        <Input placeholder="..." type="form-control" name="heart" value={heart_rate} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Respiratory rate(per minute)</label>
                        <Input placeholder="..." type="form-control" name="respiratory" value={respiratory_rate} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Temperature(ºF)</label>
                        <Input placeholder="..." type="form-control" name="temperature" value={temp} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Oxygen saturation(%)</label>
                        <Input placeholder="..." type="form-control" name="oxygen" value={oxygen_saturation} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group d-flex h-100 flex-column justify-content-between">
                        <label>Weight(lbs)</label>
                        <Input placeholder="..." type="form-control" name="weight" value={mass} handle={this.inputHandler} inputType="text" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-md-2">
                      <div className="form-group">
                        <h5>Headache</h5>
                        <div className="d-flex">
                          <label>no</label>
                          <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" value={headache_state} id="headache" onChange={this.checkBoxHandler}/>
                            <label className="custom-control-label" htmlFor="headache"></label>
                          </div>
                          <label>yes</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group">
                        <h5>Fever</h5>
                        <div className="d-flex">
                          <label>no</label>
                          <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="fever" value={fever_state} onChange={this.checkBoxHandler}/>
                            <label className="custom-control-label" htmlFor="fever"></label>
                          </div>
                          <label>yes</label>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-2">
                      <div className="form-group">
                        <h5>Cough</h5>
                        <div className="d-flex">
                          <label>no</label>
                          <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="cough" value={cough_state} onChange={this.checkBoxHandler}/> 
                            <label className="custom-control-label" htmlFor="cough"></label>
                          </div>
                          <label>yes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {  patient.covid_19
                   ?
                    <section className="container">
                      <h2 className="text-danger mb-4"><FontAwesomeIcon icon="exclamation-triangle" /> Identification of alarm data</h2>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between form-group">
                            <label>Breathing difficulty</label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="breathingDifficulty" value={breathing_difficulty} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="breathingDifficulty"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between form-group">
                            <label>Breathing rate less than 22 per minute</label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="lowBreathingRate" value={low_breathing_rate} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="lowBreathingRate"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between form-group">
                            <label>Pulse oximeter availability </label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="oximeter" value={pulse_oximeter_availability} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="oximeter"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between form-group">
                            <label>Coma Glasgow Scale equal or less than 13 </label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="lowComa" value={low_coma_glashow_scale} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="lowComa"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between form-group">
                            <label>Systolic blood pressure less than 100mmHg</label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="lowSystolicBloodPressure" value={low_systolic_blood_pressure} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="lowSystolicBloodPressure"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between form-group">
                            <label>Exacerbation of blood glucose control</label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="exacerbation" value={exacerbation_of_blood_glucose_control} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="exacerbation"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between form-group">
                            <label>Oxygen saturation les than 94%</label>
                            <div className="d-flex">
                              <label>no</label>
                              <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="lowOxygenSaturation" value={low_oxygen_saturation} onChange={this.checkBoxHandler}/>
                                <label className="custom-control-label" htmlFor="lowOxygenSaturation"></label>
                              </div>
                              <label>yes</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  : null
                }
              </>
            :
                <section className="medication-confirmation-container">
                  <Schedule />
                  <MedicationSelection />
                </section>
          }
          <div className="break" />
          <Buttons 
            amount={
            medTechData.building_certification !== 'default'
              ?
                medicationType !== 'covid' 
                  ? 
                    status.length > 0 && meds.length > 0 && medTechData.latest_signature !== null
                      ? 
                        2 
                      : 
                        1 
                  : 
                    2
              :
                medicationType !== 'covid'
                ?
                  medicationType === 'narcotic'
                    ?
                      status.length > 0 && meds.length > 0  && patientSignature.length > 0 && medtechSignature.length > 0 || status.length > 0 && meds.length > 0  && patientSignature.length > 0 && medTechData.latest_signature !== null
                        ?
                          2
                        :
                          1
                    :
                      status.length > 0 && meds.length > 0 && patientSignature.length > 0
                        ?
                          3
                        :
                          status.length > 0 && meds.length > 0
                            ?
                              2
                            :
                              1
                  ?
                    1
                  :
                    1
                :
                  2
            } 
            options={{back: this.back, continue: this.continue, goTo: this.goTo}} 
            type={medicationType !== 'narcotic' && medicationType !== 'covid' && medTechData.building_certification === 'default' ? "confirm" : null}
          />
        </ContentCard>
        <Modal style="reasons" active={active}>
          <div className="reasons-container">
            <div><p className="r-title">Reasons</p></div>
            <div className="break-reasons" />
            <div><p className="sub-text">Specify a reason to skip</p><p className="sub-text-med">{skippedMed.name}</p></div>
            <div className="break"/>
            <div className="modal-checkbox-container">
              <div><input onClick={this.setReason} type="checkbox" name="vehicle" value="I care not to take it" /> I care not to take it</div>
              <div><input onClick={this.setReason} type="checkbox" name="vehicle" value="Med error" /> Med error</div>
              <div><input onClick={this.setReason} type="checkbox" name="vehicle" value="Not on site" /> Not on site</div>
              <div><input onClick={this.setReason} type="checkbox" name="vehicle" value="Discontinued" /> Discontinued</div>
              <div><input onClick={this.setReason} type="checkbox" name="vehicle" value="Other" /> Other</div>
            </div>
            <div className="break-reasons" />
            <Buttons amount={2} options={{modalBack: this.modalBack, modalContinue: this.modalContinue}} type="reasons" />
          </div>
        </Modal>
        <Modal style="reasons" active={otherActive}>
          <div className="reasons-container">
            <div><p className="r-title">As needed Reasons</p></div>
            <div className="break-reasons" />
            <div><p className="sub-text">Specify a reason to take this as needed medication</p><p className="sub-text-med">{asNeeded.name}</p></div>
            <div className="break"/>
            <div className="modal-checkbox-container">
              <div><input onClick={this.setAsNeededReason} checked={asNeededReason === "1"} type="checkbox" value="1" /> Headache</div>
              <div><input onClick={this.setAsNeededReason} checked={asNeededReason === "5"} type="checkbox" value="5" /> Nausea</div>
              <div><input onClick={this.setAsNeededReason} checked={asNeededReason === "3"} type="checkbox" value="3" /> Anxiety</div>
              <div><input onClick={this.setAsNeededReason} checked={asNeededReason === "2"} type="checkbox" value="2" /> Depression</div>
              <div><input onClick={this.setAsNeededReason} checked={asNeededReason === "4"} type="checkbox" value="4" /> Body Aches</div>
            </div>
            <div className="break-reasons" />
            <div><p className="sub-text">Select the amount of time for the reminder</p></div>
            <div className="break"/>
            <div className="modal-checkbox-container">
              <div><input onClick={this.setAsNeededReasonReminderTime} checked={asNeededReasonReminderTime === "0.5"} type="checkbox" value="0.5" /> 30min</div>
              <div><input onClick={this.setAsNeededReasonReminderTime} checked={asNeededReasonReminderTime === "1"} type="checkbox" value="1" /> 1hr</div>
              <div><input onClick={this.setAsNeededReasonReminderTime} checked={asNeededReasonReminderTime === "1.5"} type="checkbox" value="1.5" /> 1.5hrs</div>
              <div><input onClick={this.setAsNeededReasonReminderTime} checked={asNeededReasonReminderTime === "2"} type="checkbox" value="2" /> 2hrs</div>
            </div>
            <Buttons amount={asNeededReason.length > 0 && asNeededReasonReminderTime.length > 0 ? 2 : 1} options={{modalBack: this.asNeededModalBack, modalContinue: this.asNeededModalContinue}} type="reasons" />
          </div>
        </Modal>
        <Modal style="options" active={optionsActive}>
          <div className="options-cont">
            <div className="options-name-container">
              <p className="options-name-txt">{`${patient.name} ${patient.last_name.charAt(0)}.`}</p>
            </div>
            <div className="options-txt-container">
              <p className="options-txt">Would you like to continue with another process?</p>
            </div>
            {
              this.renderOptions()
            }
            <div className="break" style={{marginTop: 37, marginBottom: 37}} />
            <div className="options-exit-container">
              <p className="or-txt">Or</p>
              <Button type="exit" text="EXIT" onClick={this.exit} />
            </div>
          </div>
        </Modal>
        <Modal active={medtechSignatureModalActive}>
          <Signature pad={medtechPad} setSignature={this.setSignature} type="medtech" clearSignature={this.clearSignature} closeModal={this.closeModal} signature={medTechData.building_certification === 'CBLA' ? medTechData.latest_signature : medtechSignature} />
        </Modal>
        <Modal active={patientSignatureModalActive}>
          <Signature pad={patientPad} type="patient" setSignature={this.setSignature} clearSignature={this.clearSignature} closeModal={this.closeModal} signature={patientSignature} />
        </Modal>
        <SweetAlert
        show={saved_successful}
        title="Success!"
        text="Diagnoses sent"
        onConfirm={this.reset}
      />
      </>
    );
  };
};

const mapStateToProps = state => ({
  emoji: state.emoji.emoji,
  medTech: state.patientSession.med_tech,
  patient: state.patientSession.patient,
  status: state.patientSession.status,
  schedule: state.patientSession.schedule,
  medicationType: state.patientSession.medicationType,
  settings: state.settings,
  domain: state.domain.domain,
  loginResponse: state.login.loginResponse,
  temp: state.corona.temperature,
  blood_pressure: state.corona.blood_pressure,
  heart_rate: state.corona.heart_rate,
  respiratory_rate: state.corona.respiratory_rate,
  oxygen_saturation: state.corona.oxygen_saturation,
  mass: state.corona.weight,
  headache_state: state.corona.headache,
  fever_state: state.corona.fever,
  cough_state: state.corona.cough,
  breathing_difficulty: state.corona.breathing_difficulty,
  low_breathing_rate: state.corona.low_breathing_rate,
  low_systolic_blood_pressure: state.corona.low_systolic_blood_pressure,
  low_coma_glashow_scale: state.corona.low_coma_glashow_scale,
  exacerbation_of_blood_glucose_control: state.corona.exacerbation_of_blood_glucose_control,
  pulse_oximeter_availability: state.corona.pulse_oximeter_availability,
  low_oxygen_saturation: state.corona.low_oxygen_saturation,
  saved_successful: state.corona.diagnoses_sent,
  meds: state.patientSession.formattedMedication,
  isAmend: state.amend.amend,
  med_event_success: state.med_event.med_event_success,
  narcoticToSign: state.narcotic.narcoticToSign,
  medsList: state.patientSession.medication,
  medtechSignature: state.patientSession.medtechSignature,
  patientSignature: state.patientSession.patientSignature,
  refillToSign: state.refills.refillToSign,
  skippedMed: state.modal.medToSkip,
  asNeeded: state.modal.asNeeded,
  otherActive: state.modal.otherActive,
  optionsActive: state.modal.optionsActive,
  asNeededReason: state.modal.asNeededReason,
  active: state.modal.active,
  asNeededReasonReminderTime: state.modal.asNeededReasonReminderTime,
  formattedSelectedMedication: state.patientSession.formattedMedication,
  selectedMedication: state.patientSession.medication,
  patientSignatureModalActive: state.modal.patientSignatureModalActive,
  medtechSignatureModalActive: state.modal.medtechSignatureModalActive,
});

export default connect(mapStateToProps, { 
  setEmoji, 
  setStatus, 
  logOut, 
  clearDomain, 
  clearPatientSession, 
  clearEmoji, 
  temperature, 
  blood, 
  heart, 
  weight, 
  oxygen, 
  respiratory, 
  headache, 
  fever,
  cough,
  breathingDifficulty,
  lowBreathingRate,
  lowSystolicBloodPressure,
  lowComa,
  exacerbation,
  oximeter,
  lowOxygenSaturation,
  sendPatientDiagnoses,
  resetDiagnoses,
  clearComplete,
  clearMedEvent,
  activateModal,
  deleteNarcoticFromList,
  deactivateModal,
  clearPartialSession,
  setPatientMedsType,
  medSigning,
  refillSigning,
  activateOtherModal,
  deactivateOtherModal,
  setPadStyle,
  setOtherPadStyle,
  activatePatientSignature,
  activateMedtechSignature,
  setAsNeededMed, 
  setAsNeededReason, 
  setAsNeededReminderTime,
  clearAsNeededModalData,
  handleOptionsModal,
  restorePatientFormattedMed,
  restorePatientMed,
  setPatientFormattedMed,
  setPatientMed,
  setMedtechSignature,
  setPatientSignature,
  setReasonToSkip,
  clearReasons,
  clearPatientSignature,
  clearMedTechSignature,
})(ConfirmResident);