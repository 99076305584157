import { GET_MEDS, CLEAR_COMPLETE } from '../actions/types';

const initialState = {
  meds: [],
  complete: false,
};

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_MEDS:
      return {
        ...state,
        meds: action.payload,
        complete: true,
      }
    case CLEAR_COMPLETE:
      return {
        ...state,
        complete: false
      }
    default:
      return state;
  }
}