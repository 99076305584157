import { 
  SET_STATUS,
  SET_PATIENT_INFO,
  SET_MEDS,
  SET_PATIENT_SIGNATURE,
  SET_MEDTECH_SIGNATURE,
  SET_MEDICATION_TYPE,
  SET_MEDICATION_SCHEDULE,
  SET_MEDTECH_INFO,
  SET_FORMATTED_MEDICATION,
  RESTORE_MED,
  RESTORE_FORMATTED_MED,
  SET_SCHEDULE_CHANGE,
  SET_SCHEDULE_CHANGE_FORMATTED,
  CLEAR_PATIENT_SESSION,
  DELETE_NARCOTIC_FROM_LIST,
  CLEAR_PARTIAL_SESSION,
  CLEAR_MEDICATION,
  CLEAR_PATIENT_SIGNATURE,
  CLEAR_MEDTECH_SIGNATURE,
} from '../actions/types';

const initialState = {
  status: "",
  med_tech: {},
  patient: {},
  formattedMedication: [],
  medication: [],
  medicationType: "",
  schedule: "",
  patientSignature: "",
  medtechSignature: "",
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case SET_MEDTECH_INFO:
        return {
          ...state,
          med_tech: action.payload
        }
    case SET_PATIENT_INFO:
      return {
        ...state,
        patient: action.payload
      }
    case SET_FORMATTED_MEDICATION:
      return {
        ...state,
        formattedMedication: [...state.formattedMedication, action.payload]
      }
    case RESTORE_MED:
      return {
        ...state,
        medication: action.payload
      }
    case RESTORE_FORMATTED_MED:
        return {
          ...state,
          formattedMedication: action.payload
        }
    case SET_MEDS:
    return {
      ...state,
      medication: [...state.medication, action.payload]
    }
    case SET_SCHEDULE_CHANGE:
    return {
      ...state,
      medication: action.payload
    }
    case SET_SCHEDULE_CHANGE_FORMATTED:
    return {
      ...state,
      formattedMedication: action.payload
    }
    case SET_MEDICATION_TYPE:
      return {
        ...state,
        medicationType: action.payload
      }
    case SET_MEDICATION_SCHEDULE:
    return {
      ...state,
      schedule: action.payload
    }
    case SET_PATIENT_SIGNATURE:
      return {
        ...state,
        patientSignature: action.payload
      }
    case SET_MEDTECH_SIGNATURE:
      return {
        ...state,
        medtechSignature: action.payload
      }
    case CLEAR_PATIENT_SIGNATURE:
      return {
        ...state,
        patientSignature: ""
      }
    case CLEAR_MEDTECH_SIGNATURE:
      return {
        ...state,
        medtechSignature: ""
      }
    case CLEAR_PATIENT_SESSION:
      return {
        ...state,
        status: "",
        patient: {},
        formattedMedication: [],
        medication: [],
        medicationType: "",
        schedule: "",
        patientSignature: "",
        medtechSignature: "",
      }
    case CLEAR_PARTIAL_SESSION:
        return {
          ...state,
          formattedMedication: [],
          medication: [],
          medicationType: "",
          schedule: "",
          patientSignature: "",
          medtechSignature: "",
        }
    case CLEAR_MEDICATION:
      return {
        ...state,
        formattedMedication: [],
        medication: [],
        schedule: ""
      }
    case DELETE_NARCOTIC_FROM_LIST:
      return {
        ...state,
        medication: action.payload
      }
    default:
      return state;
  }
}