import { GET_PATIENT_REFILLS, SET_REFILL_TO_SIGN} from '../actions/types';

const initialState = {
  refills: [],
  refillToSign: {},
};

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_PATIENT_REFILLS:
      return {
        ...state,
        refills: action.payload
      }
    case SET_REFILL_TO_SIGN:
      return {
        ...state,
        refillToSign: action.payload
      }
    default:
      return state;
  }
}