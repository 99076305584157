import { GET_PATIENTS, CHANGE_SEARCH } from './types';
import { getPatients } from '../api/patients';

const getResidencePatients = (URL, authHeaders, query, page) => dispatch => {
    return getPatients(URL, authHeaders, query, page)
        .then(patients => {
            dispatch({
                type: GET_PATIENTS,
                payload: patients.data,
            });

            return Promise.resolve();
        })
        .catch(err => {
            if (err.response && err.response.status === 404) {
                alert("No residents were found");

                dispatch({
                    type: CHANGE_SEARCH,
                    payload: ""
                })
            }

            return Promise.reject(err);
        });
}

export {
  getResidencePatients
}