import { SET_DOMAIN, SET_DOMAIN_FLAG, GET_DOMAIN, CLEAR_DOMAIN } from '../actions/types';

const initialState = {
  domain: "",
  domainSet: false
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_DOMAIN:
      return {
        ...state,
        domain: action.payload,
      }
    case SET_DOMAIN_FLAG:
      return {
        ...state,
        domainSet: true,
      }
    case GET_DOMAIN:
    return {
      state
    }
    case CLEAR_DOMAIN:
      return {
        ...state,
        domain: "",
        domainSet: false
      }
    default:
      return state;
  }
}