import { 
    SET_VITAL_SIGN, 
    SET_TEMPERATURE,
    SET_BLOOD_PRESSURE, 
    SET_HEART_RATE, 
    SET_RESPIRATORY_RATE, 
    SET_OXYGEN_SATURATION,
    SET_WEIGHT,
    SET_HEADACHE,
    SET_FEVER,
    SET_COUGH,
    SET_BREATHING_DIFFICULTY,
    SET_LOW_BREATHING_RATE,
    SET_LOW_SYSTOLIC_BLOOD_PRESSURE,
    SET_LOW_COMA_GLASHOW_SCALE,
    SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL,
    SET_PULSE_OXIMETER_AVAILABILITY,
    SET_LOW_OXYGEN_SATURATION,
    DIAGNOSE_PATIENT_SUCCESS,
    DIAGNOSE_PATIENT_FAILURE,
    RESET_DIAGNOSES,
} from '../actions/types';

const initialState = {
  vital_signs: [],
  temperature: "",
  blood_pressure: "",
  heart_rate: "",
  respiratory_rate: "",
  oxygen_saturation: "",
  weight: "",
  headache: false,
  fever: false,
  cough: false,
  breathing_difficulty: false,
  low_breathing_rate: false,
  low_systolic_blood_pressure: false,
  low_coma_glashow_scale: false,
  exacerbation_of_blood_glucose_control: false,
  pulse_oximeter_availability: false,
  low_oxygen_saturation: false,
  diagnoses_sent: false,
  error: {},
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_VITAL_SIGN:
      return {
        ...state,
        vital_signs: [...state.vital_signs, action.payload],
      }
    case SET_TEMPERATURE:
      return {
        ...state,
        temperature: action.payload
      }
    case SET_BLOOD_PRESSURE:
      return {
        ...state,
        blood_pressure: action.payload
      }
    case SET_HEART_RATE:
    return {
        ...state,
        heart_rate: action.payload
    }
    case SET_RESPIRATORY_RATE:
    return {
        ...state,
        respiratory_rate: action.payload
    }
    case SET_OXYGEN_SATURATION:
    return {
        ...state,
        oxygen_saturation: action.payload
    }
    case SET_WEIGHT:
    return {
        ...state,
        weight: action.payload
    }
    case SET_FEVER:
    return {
        ...state,
        fever: action.payload
    }
    case SET_COUGH:
    return {
        ...state,
        cough: action.payload
    }
    case SET_HEADACHE:
    return {
        ...state,
        headache: action.payload
    }
    case SET_BREATHING_DIFFICULTY:
    return {
        ...state,
        breathing_difficulty: action.payload
    }
    case SET_LOW_BREATHING_RATE:
    return {
        ...state,
        low_breathing_rate: action.payload
    }
    case SET_LOW_SYSTOLIC_BLOOD_PRESSURE:
    return {
        ...state,
        low_systolic_blood_pressure: action.payload
    }
    case SET_LOW_COMA_GLASHOW_SCALE:
    return {
        ...state,
        low_coma_glashow_scale: action.payload
    }
    case SET_EXACERBATION_OF_BLOOD_GLUCOSE_CONTROL:
    return {
        ...state,
        exacerbation_of_blood_glucose_control: action.payload
    }
    case SET_PULSE_OXIMETER_AVAILABILITY:
    return {
        ...state,
        pulse_oximeter_availability: action.payload
    }
    case SET_LOW_OXYGEN_SATURATION:
    return {
        ...state,
        low_oxygen_saturation: action.payload
    }
    case DIAGNOSE_PATIENT_SUCCESS:
    return {
        ...state,
        diagnoses_sent: true
    }
    case DIAGNOSE_PATIENT_FAILURE:
    return {
        ...state,
        diagnoses_sent: false,
        error: action.payload
    }
    case RESET_DIAGNOSES:
    return {
        ...state,
        diagnoses_sent: false,
        temperature: "",
        blood_pressure: "",
        heart_rate: "",
        respiratory_rate: "",
        oxygen_saturation: "",
        weight: "",
        headache: false,
        fever: false,
        cough: false,
        breathing_difficulty: false,
        low_breathing_rate: false,
        low_systolic_blood_pressure: false,
        low_coma_glashow_scale: false,
        exacerbation_of_blood_glucose_control: false,
        pulse_oximeter_availability: false,
        low_oxygen_saturation: false,    
    }
    default:
      return state;
  };
};