import { SET_EMOJI, CLEAR_EMOJI } from './types';

const setEmoji = (emoji) => dispatch => {
    dispatch({
        type: SET_EMOJI,
        payload: emoji,
    })
}

const clearEmoji = () => dispatch => {
  dispatch({
    type: CLEAR_EMOJI,
  })
}

export {
  setEmoji,
  clearEmoji,
}