import {
    GET_TABS,
    GET_TABS_SUCCESS,
    HANDLE_FAILURE,
    GET_LOGS_PATIENTS,
    GET_LOGS_PATIENTS_SUCCESS,
    CHANGE_MAIN_TAB,
    CHANGE_TAB,
    UPDATE_LOG_UNITS,
    UPDATE_LOG_SUCCESS,
    DELETE_LOG_SUCCESS,
    CHANGE_SEARCH,
    CREATE_LOG_SUCCESS
} from './types';

import {
    dailyLogs,
    dailyLogsPatients,
    createPatientLog,
    updatePatientLog,
    deletePatientLog
} from '../api/logs';
  
    const dailyLogsTabs = (URL, authHeaders) => dispatch => {
        dispatch({ type: GET_TABS });

        dailyLogs(URL, authHeaders)
            .then(response => {
                dispatch({
                    type: GET_TABS_SUCCESS,
                    payload: response.data.daily_logs,
                })
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_FAILURE,
                    payload: err,
                });
            });
    };

    const createDailyLog = (URL, authHeaders, log) => dispatch => {
        const body = {
            daily_log_events: [{
                daily_log_id: log.dailyLogGroup,
                patient_id: log.patientId,
                eventdate: log.date,
                schedule: log.type,
                units: log.units
            }]
        };

        createPatientLog(URL, authHeaders, body)
            .then(response => {
                const serviceLog = response.data.daily_log_events[0];

                const domainLog = {
                    id: serviceLog.id,
                    dailyLogGroup: serviceLog.daily_log_id,
                    patientId: serviceLog.patient_id,
                    date: serviceLog.eventdate,
                    type: serviceLog.schedule,
                    units: serviceLog.units
                };

                dispatch({
                    type: CREATE_LOG_SUCCESS,
                    payload: domainLog
                })
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_FAILURE,
                    payload: err,
                })
            });
    };

    const updateDailyLogUnits = (log) => dispatch => {
        dispatch({
            type: UPDATE_LOG_UNITS,
            payload: log
        });
    };

  const updateDailyLog = (URL, authHeaders, log) => dispatch => {
    updatePatientLog(URL, authHeaders, log)
        .then(() => {
            dispatch({type: UPDATE_LOG_SUCCESS})
        })
        .catch(err => {
            dispatch({
                type: HANDLE_FAILURE,
                payload: err,
            })
    });
};

    const deleteDailyLog = (URL, authHeaders, event) => dispatch => {
        deletePatientLog(URL, authHeaders, event)
            .then(response => {
                dispatch({
                    type: DELETE_LOG_SUCCESS,
                    payload: response.data,
                })
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_FAILURE,
                    payload: err,
                })
            });
    };

const changeMainTab = tab => dispatch => {
    dispatch({
        type: CHANGE_MAIN_TAB,
        payload: tab
    })
};

  const changeTab = (tab, name) => dispatch => {
      dispatch({
          type: CHANGE_TAB,
          payload: { tab, name }
      })
  };

    const getDailyLogsPatients = (URL, authHeaders, typeId, search) => dispatch => {
        dispatch({type: GET_LOGS_PATIENTS});

        dailyLogsPatients(URL, authHeaders, typeId, search)
            .then(response => {

                let responseData = response.data;

                let dailyLogs = responseData.patients.reduce((dailyLog, patient) => {
                    dailyLog[patient.id] = {
                        patient: {
                            profile_picture: patient.profile_picture,
                            name: patient.name,
                            lastName: patient.last_name,
                            room: patient.room
                        },
                        morning: {
                            id: patient.morning_event.id,
                            units: patient.morning_event.units,
                            type: "morning",
                            date: patient.morning_event.eventdate,
                            dailyLogGroup: typeId,
                            patientId: patient.id
                        },
                        noon: {
                            id: patient.noon_event.id,
                            units: patient.noon_event.units,
                            type: "noon",
                            date: patient.noon_event.eventdate,
                            dailyLogGroup: typeId,
                            patientId: patient.id
                        },
                        evening: {
                            id: patient.evening_event.id,
                            units: patient.evening_event.units,
                            type: "evening",
                            date: patient.evening_event.eventdate,
                            dailyLogGroup: typeId,
                            patientId: patient.id
                        },
                        bedtime: {
                            id: patient.bedtime_event.id,
                            units: patient.bedtime_event.units,
                            type: "bedtime",
                            date: patient.bedtime_event.eventdate,
                            dailyLogGroup: typeId,
                            patientId: patient.id
                        },
                        [patient.other_event.schedule]: {
                            id: patient.other_event.id,
                            units: patient.other_event.units,
                            type: patient.other_event.schedule,
                            date: patient.other_event.eventdate,
                            dailyLogGroup: typeId,
                            patientId: patient.id
                        }
                    };
                    return dailyLog;
                }, {});

                dispatch({
                    type: GET_LOGS_PATIENTS_SUCCESS,
                    payload: {
                        dailyLogs: dailyLogs,
                        activeLog: responseData.daily_log
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: HANDLE_FAILURE,
                    payload: err,
                })
            });
    };

const changeSearch = (search) => dispatch => {
    dispatch({
        type: CHANGE_SEARCH,
        payload: search, 
    })
};

export {
    dailyLogsTabs,
    getDailyLogsPatients,
    changeMainTab,
    changeTab,
    createDailyLog,
    updateDailyLog,
    deleteDailyLog,
    updateDailyLogUnits,
    changeSearch
}