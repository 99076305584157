import { GET_SETTINGS } from './types';
import { getTenantSettings } from '../api/login';

const getSettings = (URL, authHeaders) => dispatch => {
    getTenantSettings(URL, authHeaders)
        .then(response => dispatch({
            type: GET_SETTINGS,
            payload: response.data
        }))
};

export { getSettings };