import React from 'react';
import './contentCardStyles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrescriptionBottleAlt, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faPrescriptionBottleAlt, faCalendarCheck);

const Card = ({children, mainTab, changeMainTab, medTech}) => (
  <main className="content-container">
    <div className="tabs-container">
      {medTech ? medTech.role !== 'daily_log' && <div className={ changeMainTab === undefined ? "adherence-tab-active" : mainTab === 1 ? "adherence-tab-active" : 'adherence-tab' } onClick={changeMainTab === undefined ? null : () => changeMainTab(1)}>
        <FontAwesomeIcon icon="prescription-bottle-alt"  />
        <label style={{marginLeft: 5}}>Medication adherence</label>
      </div> : <div className={ changeMainTab === undefined ? "adherence-tab-active" : mainTab === 1 ? "adherence-tab-active" : 'adherence-tab' } onClick={changeMainTab === undefined ? null : () => changeMainTab(1)}>
        <FontAwesomeIcon icon="prescription-bottle-alt"  />
        <label style={{marginLeft: 5}}>Medication adherence</label>
      </div>}
      <div className={ mainTab === 2 ? "daily-tab-active" : "daily-tab" } onClick={changeMainTab === undefined ? null : () => changeMainTab(2)}>
        <FontAwesomeIcon icon="calendar-check"  />
        <label style={{marginLeft: 5}}>Daily logs</label>
      </div>
    </div>
    <section id="middle" className="content-card">{children}</section>
  </main>
);

export default Card;