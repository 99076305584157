import { SET_SCHEDULE_TO_CHANGE, SET_SCHEDULE_CHANGE_MED } from './types';

const setScheduleChange = (schedule) => dispatch => {
    dispatch({
        type: SET_SCHEDULE_TO_CHANGE,
        payload: schedule,
    })
}

const setScheduleChangeMed = (med) => dispatch => {
    dispatch({
        type: SET_SCHEDULE_CHANGE_MED,
        payload: med,
    })
}

export {
  setScheduleChange,
  setScheduleChangeMed
}