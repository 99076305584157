import React from 'react';
import './listStyles.css';
import Button from '../../shared/components/Button/Button';
import Avatar from '../../shared/images/ico-avatar.png';
import Covid from '../../shared/images/ico-covid-white-1x.png';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrescriptionBottleAlt, faPills, faPrescriptionBottle, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faPrescriptionBottleAlt, faPrescriptionBottle, faPills, faHeartbeat);

const renderOptions = (patient, optionSelection, certificate) => {
  if(certificate === 'default') {
     if(patient.takes_narcotic && patient.pending_refills && patient.active_vital_signs) {
      return (
        <div className="rs-options-container">
          <Button type="option" onClick={() => optionSelection("regular", patient)}>
            <FontAwesomeIcon icon="pills" />
            <label>select</label>
          </Button>
          <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
            <FontAwesomeIcon icon="prescription-bottle-alt" />
            <label>narcotic</label>
          </Button>
          <Button type="option-refill" onClick={() => optionSelection("refill", patient)}>
            <FontAwesomeIcon icon="prescription-bottle" />
            <label>refill</label>
          </Button>
          <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
            { patient.covid_19
              ?
                <img src={Covid} alt="icon" />
              :
                <FontAwesomeIcon icon="heartbeat" />
            }
            <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
          </Button>
        </div>
      )
    } else if(patient.takes_narcotic && patient.pending_refills) {
      return (
        <div className="rs-options-container">
          <Button type="option" onClick={() => optionSelection("regular", patient)}>
            <FontAwesomeIcon icon="pills" />
            <label>select</label>
          </Button>
          <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
            <FontAwesomeIcon icon="prescription-bottle-alt" />
            <label>narcotic</label>
          </Button>
          <Button type="option-refill" onClick={() => optionSelection("refill", patient)}>
            <FontAwesomeIcon icon="prescription-bottle" />
            <label>refill</label>
          </Button>
        </div>
      )
    } else if(patient.takes_narcotic && patient.pending_refills===false && patient.active_vital_signs ) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
              <label>narcotic</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
              { patient.covid_19
                ?
                  <img src={Covid} alt="icon" />
                :
                  <FontAwesomeIcon icon="heartbeat" />
              }
              <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
            </Button>
          </div>
        )
    }else if(patient.takes_narcotic && patient.pending_refills===false) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
              <label>narcotic</label>
            </Button>
          </div>
        )
    }else if(patient.pending_refills ) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type="option-refill" onClick={() => optionSelection("refill", patient)}>
              <FontAwesomeIcon icon="prescription-bottle" />
              <label>refill</label>
            </Button>
          </div>
        )
    } else if(patient.covid_19 && patient.active_vital_signs) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
              { patient.covid_19
                ?
                  <img src={Covid} alt="icon" />
                :
                  <FontAwesomeIcon icon="heartbeat" />
              }
              <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
            </Button>
          </div>
        )
    } else if(patient.active_vital_signs) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
              { patient.covid_19
                ?
                  <img src={Covid} alt="icon" />
                :
                  <FontAwesomeIcon icon="heartbeat" />
              }
              <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
            </Button>
          </div>
        )
    } else {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
          </div>
        )
    }
  } else {
    if(patient.takes_narcotic && !patient.active_vital_signs ) {
      return (
        <div className="rs-options-container">
          <Button type="option" onClick={() => optionSelection("regular", patient)}>
            <FontAwesomeIcon icon="pills" />
            <label>select</label>
          </Button>
          <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
            <FontAwesomeIcon icon="prescription-bottle-alt" />
            <label>narcotic</label>
          </Button>
        </div>
      )
    } else if(patient.takes_narcotic && patient.active_vital_signs ) {
      return (
        <div className="rs-options-container">
          <Button type="option" onClick={() => optionSelection("regular", patient)}>
            <FontAwesomeIcon icon="pills" />
            <label>select</label>
          </Button>
          <Button type="option-narcotic" onClick={() => optionSelection("narcotic", patient)}>
            <FontAwesomeIcon icon="prescription-bottle-alt" />
            <label>narcotic</label>
          </Button>
          <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
            { patient.covid_19
              ?
                <img src={Covid} alt="icon" />
              :
                <FontAwesomeIcon icon="heartbeat" />
            }
            <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
          </Button>
        </div>
      )
    } else if(patient.covid_19 && patient.active_vital_signs) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
              { patient.covid_19
                ?
                  <img src={Covid} alt="icon" />
                :
                  <FontAwesomeIcon icon="heartbeat" />
              }
              <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
            </Button>
          </div>
        )
    } else if(patient.active_vital_signs) {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
            <Button type={patient.covid_19 ? "option-covid" : "vital"} onClick={() => optionSelection("covid", patient)}>
              { patient.covid_19
                ?
                  <img src={Covid} alt="icon" />
                :
                  <FontAwesomeIcon icon="heartbeat" />
              }
              <label>{patient.covid_19 ? 'covid' : 'vital'}</label>
            </Button>
          </div>
        )
    }  else {
      return (
          <div className="rs-options-container">
            <Button type="option" onClick={() => optionSelection("regular", patient)}>
              <FontAwesomeIcon icon="pills" />
              <label>select</label>
            </Button>
          </div>
        )
    }
  }
}


const List = ({data, optionSelection, certificate}) => {
  return(
    <>
    {
      data.length > 0
        ?
          data.map(patient => (
            <div className="patient-info-container">
              <div className="img-container">
                <div className="img">{patient.profile_picture === null ? <img className="avatar-img" src={Avatar} alt="avatar" /> : <img className="avatar-img" src={patient.profile_picture} alt="avatar" /> }</div>
              </div>
              <div className="patient-data-container">
                <div className="rs-name">
                  <p className="title-text">Resident Name</p>
                  <p className="rs-name-text">{`${patient.name} ${String(patient.last_name).charAt(0)}.`}</p>
                </div>
                <div className="locker-container">
                <div className="locker">
                  <p className="title-text">Locker No.</p>
                </div>
                <div className="sub-locker">
                  <p className="content-text">{patient.locker}</p>
                </div>
                </div>
                  {renderOptions(patient, optionSelection, certificate)}
              </div>
            </div>
          ))
        : 
          <div className="patient-info-container">
            <div className="patient-data-container">
              <b>No match found</b>
            </div>
          </div>
      }
    </>
  )
}

export default List;