import {GET_SETTINGS } from '../actions/types';

const initialState = {
  colors: {},
  logo: ""
}

export default (state = initialState, action) => {
  switch(action.type) {
    case GET_SETTINGS:
      return {
          ...state,
          colors: action.payload.settings,
          logo: action.payload.app_image
      }
    default:
      return state;
  }
}