import React from 'react';
import './emojiStyles.css';
import Bad from '../../images/emoji-sad.png';
import NeutralBad from '../../images/neutral-bad.png';
import NeutralHappy from '../../images/emoji-neutral.png';
import Happy from '../../images/emoji-happy.png';
import Morning from '../../images/Morning.png';
import Noon from '../../images/Noon.png';
import Afternoon from '../../images/Afternoon.png';
import Bedtime from '../../images/bed-time.png';
import All from '../../images/all.png';
import Button from '../Button/Button';
import Anxious from '../../images/icon-anxious.png'
import As_needed from '../../images/ico-as-needed.png';
import MorningWhite from '../../images/morning-white.png';
import NoonWhite from '../../images/noon-white.png';
import EveningWhite from '../../images/evening-white.png';
import AllWhite from '../../images/all-white.png';
import BedtimeWhite from '../../images/bed-time-white.png';
import AsNeededWhite from '../../images/as-needed-white.png';


const options = {
  'Bad': Bad,
  'Anxious': Anxious,
  'NeutralBad': NeutralBad,
  'NeutralHappy': NeutralHappy,
  'Happy': Happy,
  'Morning': Morning,
  'Noon': Noon,
  'Afternoon': Afternoon,
  'Bedtime': Bedtime,
  'All': All,
  'As_needed': As_needed,
  'MorningWhite': MorningWhite,
  'NoonWhite': NoonWhite,
  'EveningWhite': EveningWhite,
  'AllWhite': AllWhite,
  'BedtimeWhite': BedtimeWhite,
  'As_neededWhite': AsNeededWhite,
};

const Emoji = ({option, text, select, type}) => {
  return(
    <Button type={type} onClick={select}>
      <img src={options[`${option}`]} alt="icon" className="respond" />
      <p>{text}</p>
    </Button>
  );
};

export default Emoji;