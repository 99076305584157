import { MED_EVENT_SUCCESS, MED_EVENT_FAILED, CLEAR_MED_EVENT } from '../actions/types';

const initialState = {
  med_event_success: ""
}

export default (state = initialState, action) => {
  switch(action.type) {
    case MED_EVENT_SUCCESS:
    return {
        ...state,
        med_event_success: action.payload
    }
    case MED_EVENT_FAILED:
    return {
        ...state,
        med_event_success: action.payload
    }
    case CLEAR_MED_EVENT:
    return {
        ...state,
        med_event_success: action.payload
    }
      default:
        return state;
    }
  }