import axios from 'axios';

const createInstance = (URL, headers = {}) => {
    const instance = axios.create({
        baseURL: URL,
        headers: {
          'Content-Type': 'application/json',
          'access-token': headers["access-token"],
          'client': headers["client"],
          'uid': headers["uid"]
        },
        timeout: 5000,
    });

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (403 === error.response.status) {
            alert("Your session has expired");
            window.location.href = "/#/login";
        }
        return Promise.reject(error);
    });

    return instance;
  };

export { createInstance };