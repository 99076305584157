import { SET_AMEND } from '../actions/types';

const initialState = {
  amend: false
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_AMEND:
      return {
        ...state,
        amend: action.payload
      }
    default:
      return state;
  }
}