/*Component not being used due to the unification of the UI with dashboard*/

import React, {Component} from 'react';
import Header from '../../shared/components/Header/Header';
import ContentCard from '../../shared/components/ContentCard/ContentCard';
import StatusBar from '../../shared/components/StatusBar/StatusBar';
import Signature from '../../shared/components/Signature/Signature';
import Buttons from '../../shared/components/Buttons/Buttons';
import Modal from '../../shared/components/Modal/Modal';
import {
  setPatientSignature,
  setMedtechSignature,
  clearPatientSession,
  clearPartialSession,
  deleteNarcoticFromList,
  setPatientMedsType,
  setStatus,
  clearMedTechSignature,
  clearPatientSignature,
} from '../../actions/patientSessionActions';
import { 
  activateModal,
  deactivateModal,
  activateOtherModal,
  deactivateOtherModal,
  setPadStyle,
  setOtherPadStyle,
  activatePatientSignature,
  activateMedtechSignature,
} from '../../actions/modalActions';
import { 
  refillSigning,
  medSigning,
  clearMedEvent
} from '../../actions/medeventActions';
import { 
  setAvailableNarcoticAmount,
  updateAvailableNarcotics, 
  setAmountToSend
} from '../../actions/narcoticActions';

import { logOut, getMedtechData } from '../../actions/loginActions';
import { clearDomain } from '../../actions/domainActions';
import { clearEmoji, setEmoji} from '../../actions/emojiActions';
import { getResidencePatients } from '../../actions/patientsActions';
import { clearComplete } from '../../actions/medsActions';
import { fullScreen, blockPadButtons } from '../utils';
import { connect } from 'react-redux';
import './medicationSigningStyles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrescriptionBottleAlt, faPills, faPrescriptionBottle, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { medtechData } from '../../api/login';
import SweetAlert from 'sweetalert2-react';

library.add(faPrescriptionBottleAlt, faPrescriptionBottle, faPills, faHeartbeat);

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;

let patientPad = {}
let medtechPad = {}

class MedicationSigning extends Component {
  state = {
    comment: '',
    showAlert: false,
  }

  back = () => {
    const { medicationType } = this.props;
    if(medicationType === 'refill') {
        this.props.history.push('/refills')
        this.props.clearPatientSignature()
      }
      else {
        this.props.history.push('/confirm');
      }
  }

  setSignature = (signature, type) => {
    console.log('setting ', type, 'signature')
    type === 'patient' ? this.props.setPatientSignature(signature): this.props.setMedtechSignature(signature);
  }

  clearSignature = (type) => {
    type === 'patient'
    ? this.props.clearPatientSignature()
    : this.props.clearMedTechSignature();
  }

  componentDidMount() {
    const { medicationType, narcoticToSign } = this.props;
    const { available } = narcoticToSign;
    if(medicationType === 'narcotic'){
      this.props.setAvailableNarcoticAmount(available) 
    }
    blockPadButtons();
  }

  renderButtons = () => {
    const { medicationType, patientSignature, medtechSignature, medTech } = this.props;
    const { data: medTechData } = medTech;
    const { building_certification } = medTechData;

    let numberOfButtons;
    if(building_certification === 'default') {
      if(medicationType === 'refill' && patientSignature.length > 0 && medtechSignature.length > 0 || medicationType === 'refill' && patientSignature.length > 0 && medTechData.latest_signature !== null) {
        numberOfButtons = 2;
      } else if(medicationType === 'narcotic' && patientSignature.length > 0 && medtechSignature.length > 0 || medicationType === 'narcotic' && patientSignature.length > 0 && medTechData.latest_signature !== null) {
        numberOfButtons = 2;
      } else if(medicationType === 'regular' && patientSignature.length > 0) {
        numberOfButtons = 2;
      } else {
        numberOfButtons = 1;
      }
    } else {
      if(medicationType === 'narcotic'  && medTechData.latest_signature !== null) {
        numberOfButtons = 2
      } else if(medicationType === 'narcotic' && medtechSignature.length > 0) {
        numberOfButtons = 2;
      } else if(medicationType === 'regular' && medTechData.latest_signature !== null) {
        numberOfButtons = 2;
      } else if(medicationType === 'regular' && medtechSignature.length > 0) {
        numberOfButtons = 2;
      } else {
        numberOfButtons = 1;
      }
    }
    return numberOfButtons;
  }

  renderPatients = () => {
    const { loginResponse, domain } = this.props;
    const { headers } = loginResponse;
    this.props.getResidencePatients(`https://${domain}.${DOMAIN_EXTENSION}`, headers, "");
  }

  setPatientStatus = (emoji) => {
    this.props.setEmoji(emoji)
    this.props.setStatus(emoji)
  };
  
  sendMedEvent = () => {
    const { medicationType, refillToSign, patientSignature, medtechSignature, status, meds, domain, loginResponse, isAmend, medTech, clearComplete, getMedtechData } = this.props;
    const { comment } = this.state;
    const { data: medTechData } = medTech;
    const { building_certification } = medTechData;
    const { headers } = loginResponse;
    const { id } = refillToSign;
    const medTechSignature = medTechData.latest_signature !== null ? medTechData.latest_signature : medtechSignature;
    clearComplete();
    medicationType === 'refill' 
      ? this.props.refillSigning(`https://${domain}.${DOMAIN_EXTENSION}`, headers, id, patientSignature, medtechSignature)
      : this.props.medSigning(`https://${domain}.${DOMAIN_EXTENSION}`, headers, meds, status, patientSignature,  medTechSignature, medicationType, isAmend, building_certification, comment);
  }

  async componentDidUpdate(prevProps) {
    const {med_event_success, medicationType, narcoticToSign, medsList, domain, loginResponse, narcoticAmountAvailable} = this.props;
    const { headers } = loginResponse;
    if(med_event_success === true && medicationType === 'refill') {
      await this.renderPatients();
      this.props.clearMedEvent(false);
      this.props.clearPatientSignature()
      this.props.history.push('/refills');
      this.props.getMedtechData(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
    } else if(med_event_success === true && medicationType === 'narcotic') {
      this.props.deleteNarcoticFromList(narcoticToSign, medsList)
      this.props.clearMedEvent(false);
      this.props.history.push('/medication_schedule_confirmation')
      this.props.getMedtechData(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
    } else if(med_event_success === true) {
      this.props.clearMedEvent(false);
      this.props.activateModal(true);
      this.props.getMedtechData(`https://${domain}.${DOMAIN_EXTENSION}`, headers);
    }
    if(prevProps.narcoticAmountAvailable !== narcoticAmountAvailable) {
      this.setState({showAlert: true});
    }
  }

  commentHandler = (e) => {
    this.setState({[ e.target.name ]: e.target.value }, () => console.log(this.state.comment))
  }

  renderComments = () => {
    const { comment } = this.state;
    return(
      <div className="comments-container">
        <input 
          className="comments-input" 
          type="text" 
          placeholder="Any comment? Type here."
          value={comment}
          name="comment" 
          onChange={(e) => this.commentHandler(e)}
        />
      </div>
    )
  }

  exit = () => {
    this.props.clearPatientSession();
    this.props.clearEmoji();
    this.props.deactivateModal(false);
    this.props.history.push('/patients');
  }

  activateUpdateModal = () => {
    this.props.activateOtherModal(true);
  }

  deactivateUpdateModal = () => {
    this.props.deactivateOtherModal(false);
  }

  updateAvailableNarcotics = () => {
    const { narcoticToSign, domain, loginResponse, amountToSend } = this.props;
    const { headers } = loginResponse;
    const { id } = narcoticToSign;
    this.props.updateAvailableNarcotics(`https://${domain}.${DOMAIN_EXTENSION}`, headers, id, amountToSend)
    this.props.deactivateOtherModal(false);
    this.props.setAmountToSend('');
  }

  setAmountToSend = (e) => {
    this.props.setAmountToSend(e.target.value);
  }

  find = (type) => {
    const { meds } = this.props;
    const amount = meds.filter(med => (
      med.event_name === type 
    ))
    return amount.length;
  }

  activatePatientSignatureModal = () => {
    this.props.activatePatientSignature(true);
  }

  activateMedtechSignatureModal = () => {
    this.props.activateMedtechSignature(true);
  }

  closeModal = (type) => {
    type === 'patient' 
      ? this.props.activatePatientSignature(false)
      : this.props.activateMedtechSignature(false);
  }

  render() {
    const {
       medicationType, 
       refillToSign, 
       otherActive,
       patient, 
       narcoticToSign, 
       status, 
       medTech, 
       narcoticAmountAvailable, 
       amountToSend, 
       logOut, 
       clearDomain, 
       schedule, 
       medtechSignature,
       patientSignature,
       settings,
       patientSignatureModalActive,
       medtechSignatureModalActive,
    } = this.props;
    const { data: medTechData } = medTech;
    const taken = this.find('takePill');
    const skipped = this.find('refusedPill');
    const postPoned = this.find('postponePill');
    const  { showAlert } = this.state;
    return(
      <>
        <Header med_tech={medTechData} logOut={logOut} clearDomain={clearDomain} settings={settings} />
        <ContentCard>
          <StatusBar step="5" patient={patient} status={status} setPatientStatus={this.setPatientStatus} medicationType={medicationType} schedule={schedule} />
          { medTechData.building_certification === 'default'
            ?
              medicationType === 'refill' || medicationType === 'narcotic'
              ?(<>
                  <div className="status-title-container">
                    <div className="status-title-text-container-rn">
                        {
                          medicationType === 'narcotic'
                          ?(<div className="narcotic-info-container">
                              <p className="status-title-text-rn"> 
                                {narcoticToSign.name} {narcoticToSign.dose}MG - Remainder {narcoticAmountAvailable}
                              </p>
                              <button className="update-inventory-btn" onClick={this.activateUpdateModal}>update inventory</button>
                            </div>)
                          : (<p className="status-title-text-rn">{refillToSign.med_name} {refillToSign.units} units</p>)
                        }
                    </div>
                  </div>
                  <div className="r-s-container"><p className="r-s-text">Resident Signature</p></div>
                  <section className="signature-container">
                    <div className="nrml-canvas" onClick={this.activatePatientSignatureModal}>
                      <img src={patientSignature} />
                    </div>
                  </section>
                  <div className="r-s-container"><p className="r-s-text">Medtech Signature</p></div>
                  <section className="signature-container">
                    <div className="nrml-canvas" onClick={this.activateMedtechSignatureModal}>
                      <img src={medTechData.latest_signature !== null ?  medTechData.latest_signature :  medtechSignature} />
                    </div>
                    {this.renderComments()}
                  </section>
                </>)
              : (<>
                  <div className="status-title-container">
                    <div className="status-title-line"></div>
                    <div className="status-title-text-container">
                      <p className="status-title-text">Please Sign</p>
                      { taken > 0 ? <p className="confirm-txt">You confirm that you will take {taken} Medication(s)</p>: null}
                      { skipped > 0 ? <p className="confirm-txt">You confirm that you will skip {skipped} Medication(s)</p>: null}
                      { postPoned > 0 ? <p className="confirm-txt">You confirm that you will postpone {postPoned} Medication(s)</p>: null}
                    </div>
                    <div className="status-title-line"></div>
                  </div>
                  <section className="signature-container">
                    <div className="nrml-canvas" onClick={this.activatePatientSignatureModal}>
                      <img src={patientSignature} />
                    </div>
                  </section>
                </>)
            :
            medicationType === 'narcotic'
              ?(<>
                  <div className="status-title-container">
                    <div className="status-title-text-container-rn">
                        {
                          medicationType === 'narcotic'
                          ?(<div className="narcotic-info-container">
                              <p className="status-title-text-rn"> 
                                {narcoticToSign.name} {narcoticToSign.dose}MG - Remainder {narcoticAmountAvailable}
                              </p>
                              <button className="update-inventory-btn" onClick={this.activateUpdateModal}>update inventory</button>
                            </div>)
                          : (<p className="status-title-text-rn">{refillToSign.med_name} {refillToSign.units} units</p>)
                        }
                    </div>
                  </div>
                  <div className="r-s-container"><p className="r-s-text">Medtech Signature</p></div>
                  <section className="signature-container">
                    <div className="nrml-canvas" onClick={this.activateMedtechSignatureModal}>
                      <img src={ medTechData.latest_signature === null ? medtechSignature : medTechData.latest_signature } />
                    </div>
                    {this.renderComments()}
                  </section>
                </>)
              : (<>
                  <div className="status-title-container">
                    <div className="status-title-line"></div>
                    <div className="status-title-text-container">
                      <p className="status-title-text">Please Sign</p>
                      { taken > 0 ? <p className="confirm-txt">You confirm that the patient will take {taken} Medication(s)</p>: null}
                      { skipped > 0 ? <p className="confirm-txt">You confirm that the patient will skip {skipped} Medication(s)</p>: null}
                      { postPoned > 0 ? <p className="confirm-txt">You confirm that the patient will postpone {postPoned} Medication(s)</p>: null}
                    </div>
                    <div className="status-title-line"></div>
                  </div>
                  <section className="signature-container">
                    <div className="nrml-canvas" onClick={this.activateMedtechSignatureModal}>
                      <img src={ medTechData.latest_signature === null ? medtechSignature : medTechData.latest_signature } />
                    </div>
                    {this.renderComments()}
                  </section>
                </>)
          
          }
          <div className="break" />
          <Buttons amount={ this.renderButtons() } options={{back: this.back, continue: medicationType === 'refill' ? this.sendMedEvent : this.back}} type="signature" />
        </ContentCard>
        <Modal style="inventory" active={otherActive}>
          <div className="update-container">
            <div className="update-title-container"><p className="update-title">Update Narcotic Inventory</p></div>
            <div className="break" />
            <div><p className="update-med-txt">{narcoticToSign.name} {narcoticToSign.dose}MG - Remainder {narcoticAmountAvailable}</p></div>
            <div className="update-info-container">
              <p>Starting count</p>
              <p>Remainder</p>
              <p>Actual remainder</p>
            </div>
            <div className="update-info-container">
              <p>{narcoticToSign.amount}</p>
              <p>{narcoticAmountAvailable}</p>
              <input type="text" className="update-input" onChange={this.setAmountToSend} value={amountToSend} />
            </div>
            <div className="break" />
            <div className="update-btn-container">
              <button className="update-close-btn" onClick={this.deactivateUpdateModal}>Close</button>
              <button className="update-update-btn" onClick={this.updateAvailableNarcotics}>Update</button>
            </div>
          </div>
        </Modal>
        <Modal active={medtechSignatureModalActive}>
          <Signature pad={medtechPad} setSignature={this.setSignature} type="medtech" clearSignature={this.clearSignature} closeModal={this.closeModal} signature={medtechSignature} />
        </Modal>
        <Modal active={patientSignatureModalActive}>
          <Signature pad={patientPad} type="patient" setSignature={this.setSignature} clearSignature={this.clearSignature} closeModal={this.closeModal} signature={patientSignature} />
        </Modal>
        <SweetAlert
        show={showAlert}
        title="Success!"
        text="remainder updated"
        onConfirm={() => this.setState({showAlert: false})}
      />
      </>
    );
  }
}

const mapStateToProps = state => ({
  refillToSign: state.refills.refillToSign,
  narcoticToSign: state.narcotic.narcoticToSign,
  medicationType: state.patientSession.medicationType,
  patientSignature: state.patientSession.patientSignature,
  medtechSignature: state.patientSession.medtechSignature,
  meds: state.patientSession.formattedMedication,
  medsList: state.patientSession.medication,
  loginResponse: state.login.loginResponse,
  status: state.patientSession.status,
  domain: state.domain.domain,
  med_event_success: state.med_event.med_event_success,
  patient: state.patientSession.patient,
  active: state.modal.active,
  medTech: state.login.loginResponse,
  otherActive: state.modal.otherActive,
  narcoticAmountAvailable: state.narcotic.amount,
  narcoticAmountAvailableUpdated: state.narcotic.updatedAvailableNarcotic,
  amountToSend: state.narcotic.amountToSend,
  isAmend: state.amend.amend,
  schedule: state.patientSession.schedule,
  padStyle: state.modal.padStyle,
  otherPadStyle: state.modal.otherPadStyle,
  settings: state.settings,
  patientSignatureModalActive: state.modal.patientSignatureModalActive,
  medtechSignatureModalActive: state.modal.medtechSignatureModalActive,
})

export default connect(mapStateToProps, { 
  setPatientSignature, 
  setMedtechSignature, 
  refillSigning,
  medSigning, 
  clearMedEvent, 
  activateModal, 
  deactivateModal, 
  clearPatientSession, 
  deleteNarcoticFromList, 
  setPatientMedsType, 
  clearPartialSession, 
  activateOtherModal, 
  deactivateOtherModal, 
  setAvailableNarcoticAmount, 
  updateAvailableNarcotics, 
  setAmountToSend, 
  logOut, 
  clearDomain, 
  clearEmoji, 
  getResidencePatients, 
  setPadStyle,
  setOtherPadStyle,
  clearPatientSignature,
  clearMedTechSignature,
  clearComplete,
  activatePatientSignature,
  activateMedtechSignature,
  getMedtechData,
  setEmoji,
  setStatus,
})(MedicationSigning);