import { SET_SCHEDULE_TO_CHANGE, SET_SCHEDULE_CHANGE_MED } from '../actions/types';

const initialState = {
  schedule: "",
  med: {}
};

export default (state = initialState, action) => {
  switch(action.type) {
    case SET_SCHEDULE_TO_CHANGE:
      return {
        ...state,
        schedule: action.payload
      }
    case SET_SCHEDULE_CHANGE_MED:
    return {
    ...state,
    med: action.payload
    }
    default:
      return state;
  }
}