import { BLOCK } from '../actions/types';

const initialState = {
  blocked: false,
}

export default (state = initialState, action) => {
  switch(action.type) {
    case BLOCK:
      return {
        ...state,
        blocked: action.payload,
      }
    default:
      return state;
  }
}