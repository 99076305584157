import { MED_EVENT_SUCCESS, MED_EVENT_FAILED, CLEAR_MED_EVENT } from './types';
import { signPatientRefill, signPatientMeds } from '../api/meds';

const refillSigning = (URL, authHeaders, refillId, patientSignature, medtechSignature) => dispatch => {
  signPatientRefill(URL, authHeaders, refillId, patientSignature, medtechSignature )
    .then(() => {
      dispatch({
        type: MED_EVENT_SUCCESS,
        payload: true,
      })
    })
    .catch(err => {
      dispatch({
        type: MED_EVENT_FAILED,
        payload: false
      })
    })
}

const medSigning = (URL, authHeaders, meds, status, patientSignature, medtechSignature, type, isAmend, certificate, comment) => dispatch => {
    signPatientMeds(URL, authHeaders, meds, status, patientSignature, medtechSignature, type, isAmend, certificate, comment )
      .then(() => {
        dispatch({
          type: MED_EVENT_SUCCESS,
          payload: true,
        })
      })
      .catch(err => {
        dispatch({
          type: MED_EVENT_FAILED,
          payload: false
        })
      })
  }

const clearMedEvent = (flag) => dispatch => {
  dispatch({
    type: CLEAR_MED_EVENT,
    payload: flag,
  })
}

export { refillSigning, medSigning, clearMedEvent };