import React, {Component} from 'react';
import Header from '../../shared/components/Header/Header';
import ContentCard from '../../shared/components/ContentCard/ContentCard';
import StatusBar from '../../shared/components/StatusBar/StatusBar';
import MedList from './MedList';
import Buttons from '../../shared/components/Buttons/Buttons';
import Modal from '../../shared/components/Modal/Modal';
import { activateModal, deactivateModal } from '../../actions/modalActions';
import { getPatientsRefills, setRefillToSign } from '../../actions/refillActions';
import { setPatientMedsType, clearPatientSession, setPatientInfo } from '../../actions/patientSessionActions';
import { logOut } from '../../actions/loginActions';
import { clearDomain } from '../../actions/domainActions';
import { connect } from 'react-redux';
import '../MedicationSelection/medicationSelectionStyles.css';
import Regular from '../../shared/images/btn_medication.png';
import Refill from '../../shared/images/btn_refill.png';
import Covid from '../../shared/images/ico-covid-white-1x.png';
import Button from '../../shared/components/Button/Button';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPrescriptionBottleAlt, faPills, faPrescriptionBottle, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './refillStyles.css';

const DOMAIN_EXTENSION = process.env.REACT_APP_DOMAIN;

library.add(faPrescriptionBottleAlt, faPrescriptionBottle, faPills, faHeartbeat);

class ScheduleConfirmation extends Component {
  componentDidMount() {
    const { domain, patient,  loginResponse } = this.props;
    const { headers } = loginResponse;
    this.props.getPatientsRefills(`https://${domain}.${DOMAIN_EXTENSION}`, headers, patient.id);
  }

  filterPatient = () => {
    const { patients, patient } = this.props;
    console.log('patients', patients, patient.id)
    const actualPatient = patients.filter(resident => (
      resident.id === patient.id
    ))
    console.log(actualPatient)
    return actualPatient[0];
  } 

  componentDidUpdate() {
    const { refills } = this.props;
    if(refills.length === 0) {
      console.log('activating')
      const newPatient = this.filterPatient();
      this.props.setPatientInfo(newPatient);
      this.props.activateModal(true)
    }
  }

  back = () => {
    this.props.history.push('/patients');
  }

  continue = () => {
    this.props.history.push('/medication_signing');
  }

  setRefillMed = (med) => {
    this.props.setRefillToSign(med);
    this.props.history.push('/medication_signing');
  }

  setOption = (type, patientData) => {
    const { status } = this.props;
    this.props.setPatientMedsType(type);
    this.props.setPatientInfo(patientData);
    type === 'refill' ? this.props.history.push('/refills') : status.length > 0 ? this.props.history.push('/schedule'): this.props.history.push('/confirm') ;
  }

  renderOptions = () => {
    const { patient, medTech } = this.props;
    const { data: medTechData } = medTech;
    const { building_certification } = medTechData;
    if(building_certification === 'default') {
      if(patient.takes_narcotic && patient.pending_refills) {
        return (
          <div className="options-container">
            <Button type="option-sml" onClick={() => this.setOption("regular")}>
              <FontAwesomeIcon icon="pills" />
            </Button>
            <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
            </Button>
            <Button type="option-refill-sml" onClick={() => this.setOption("refill")}>
              <img src={Refill} alt="icon" />
            </Button>
            <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
              {  patient.covid_19
                  ?
                    <img src={Covid} alt="icon" />
                  : <FontAwesomeIcon icon="heartbeat" />
              }
            </Button>
          </div>
        )
      } else if(patient.takes_narcotic && patient.pending_refills===false) {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
              </Button>
              <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
                <FontAwesomeIcon icon="prescription-bottle-alt" />
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : <FontAwesomeIcon icon="heartbeat" />
                }
              </Button>
            </div>
          )
      } else if(patient.pending_refills) {
        return (
            <div className="rs-options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <img src={Regular} alt="meds icon" />
              </Button>
              <Button type="option-refill-sml" onClick={() => this.setOption("refill")}>
                <img src={Refill} alt="refill icon" />
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "option-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : <FontAwesomeIcon icon="heartbeat" />
                }
              </Button>
            </div>
          )
      } else {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : <FontAwesomeIcon icon="heartbeat" />
                }
              </Button>
            </div>
          )
      }
    } else {
      if(patient.takes_narcotic) {
        return (
          <div className="options-container">
            <Button type="option-sml" onClick={() => this.setOption("regular")}>
              <FontAwesomeIcon icon="pills" />
            </Button>
            <Button type="option-narcotic-sml" onClick={() => this.setOption("narcotic")}>
              <FontAwesomeIcon icon="prescription-bottle-alt" />
            </Button>
            <Button type={patient.covid_19 ? "option-covid-sml" : "vital-sml"} onClick={() => this.setOption("covid")}>
              {  patient.covid_19
                  ?
                    <img src={Covid} alt="icon" />
                  : <FontAwesomeIcon icon="heartbeat" />
              }
            </Button>
          </div>
        )
      } else {
        return (
            <div className="options-container">
              <Button type="option-sml" onClick={() => this.setOption("regular")}>
                <FontAwesomeIcon icon="pills" />
              </Button>
              <Button type={patient.covid_19 ? "option-covid-sml" : "option-sml"} onClick={() => this.setOption("covid")}>
                {  patient.covid_19
                    ?
                      <img src={Covid} alt="icon" />
                    : <FontAwesomeIcon icon="heartbeat" />
                }
              </Button>
            </div>
          )
      }
    }
  };

  exit = () => {
    this.props.clearPatientSession();
    this.props.deactivateModal(false);
    this.props.history.push('/patients');
  }

  render() {
    const { refills, active, patient, medTech, logOut, clearDomain, medicationType, settings } = this.props;
    const { data: medTechData } = medTech;
    return(
      <>
        <Header med_tech={medTechData} logOut={logOut} clearDomain={clearDomain} settings={settings} />
        <ContentCard>
          <StatusBar step="2" patient={patient} medicationType={medicationType} />
          <div className="status-title-container">
              <div className="status-title-line"></div>
                <div className="status-title-text-container">
                    <p className="status-title-text">Refill signature</p>
                </div>
              <div className="status-title-line"></div>
            </div>
          <MedList refills={refills} setMed={this.setRefillMed} />
          <div className="break" />
          <Buttons amount={1} options={{back: this.back, continue: this.continue}} />
        </ContentCard>
        <Modal style="options" active={active}>
          <div className="options-cont">
            <div className="options-name-container">
              <p className="options-name-txt">{`${patient.name} ${patient.last_name}`}</p>
            </div>
            <div className="options-txt-container">
              <p className="options-txt">Would you like to continue with another process?</p>
            </div>
            { active
              ? this.renderOptions()
              : null
            }
            <div className="break" style={{marginTop: 37, marginBottom: 37}} />
            <div className="options-exit-container">
              <p className="or-txt">Or</p>
              <Button type="exit" text="EXIT" onClick={this.exit} />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = state => ({
  refills: state.refills.refills,
  patient: state.patientSession.patient,
  patients: state.patients.items,
  status: state.patientSession.status,
  domain: state.domain.domain,
  loginResponse: state.login.loginResponse,
  active: state.modal.active,
  medTech: state.patientSession.med_tech,
  schedule: state.patientSession.schedule,
  medicationType: state.patientSession.medicationType,
  settings: state.settings
});

export default connect(mapStateToProps, { getPatientsRefills, setRefillToSign, activateModal, deactivateModal, setPatientMedsType, clearPatientSession, setPatientInfo, logOut, clearDomain })(ScheduleConfirmation);